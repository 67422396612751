import {authStore} from "../store";
import jwt from "jsonwebtoken";

export const AuthService = {
    deleteAccount,
    login,
    logout,
    confirmAccount,
    getAccessToken,
    getAccount,
    getAuth,
    isAdmin,
    isImpersonating,
    impersonateUser,
    resetPassword,
    setAuth,
    stopImpersonating,
    updatePassword,
    verifyAccessToken,
}

const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVICE_URL
const OIDC_URL = process.env.REACT_APP_OIDC_URL
const headers = {
    'Content-Type':  'application/json',
    'X-Requested-With': 'XMLHttpRequest',
}

function login(username: string, password: string) {
    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", `${username.toLowerCase()}`);
    urlencoded.append("password", `${password}`);
    urlencoded.append("client_id", process.env.REACT_APP_IDENTITY_CLIENT_ID);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type':  'application/x-www-form-urlencoded',
        },
        body: urlencoded
    }
    return fetch(`${OIDC_URL}/token`, requestOptions)
      .then(handleResponse)
      .then(auth => {
          setAuth('auth', auth)
          return auth;
      }).catch(error => {
          return Promise.reject(error);
      });
}

function logout(loginAgain: boolean) {
    const auth = localStorage.getItem('auth')
    if (!!auth) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("refresh_token", JSON.parse(auth).refresh_token)
        urlencoded.append("client_id", process.env.REACT_APP_IDENTITY_CLIENT_ID);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            body: urlencoded
        }
        fetch(`${OIDC_URL}/logout`, requestOptions)
    }
    // remove user from local storage to log user out
    localStorage.removeItem('auth')
    localStorage.removeItem('orig_auth')
    localStorage.removeItem('impersonating')
    localStorage.removeItem('auth_expires')
    localStorage.removeItem('profile')
    authStore.clearAuth()
    window.location.assign(loginAgain ? "/login" : "/")
}

function confirmAccount(accId, token) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
    }
    return fetch(`${AUTH_SERVICE_URL}/account/confirm/${accId}?token=${token}`, requestOptions)
      .then(handleResponse)
      .then(() => {
          return true;
      });
}

function getAccount() {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    }
    return fetch(`${AUTH_SERVICE_URL}/account/`, requestOptions)
      .then(handleResponse)
      .then(account => {
          return account;
      }).catch(() => {

      });
}

function deleteAccount() {
    const requestOptions = {
        method: 'DELETE',
        headers: headers,
    }

    return fetch(`${AUTH_SERVICE_URL}/account/${getAuth().user_name}`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      }).catch(() => {

      });
}

function isAdmin() {
    const token = JSON.parse(localStorage.getItem("auth"))?.access_token
    const decodedToken = jwt.decode(token, { complete: true })
    const resourceAccess = decodedToken.payload.resource_access[process.env.REACT_APP_IDENTITY_CLIENT_ID]
    return !!resourceAccess && resourceAccess.roles.includes('admin')
}

function isImpersonating() {
    return !!localStorage.getItem('impersonating')
}

function refreshToken(refresh_token: string) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", refresh_token);
    urlencoded.append("client_id", process.env.REACT_APP_IDENTITY_CLIENT_ID);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type':  'application/x-www-form-urlencoded',
        },
        body: urlencoded
    }

    return fetch(`${OIDC_URL}/token`, requestOptions)
      .then(handleResponse)
      .then(auth => {
          return auth;
      }).catch(error => {
          if (error === "invalid_token") {
              logout();
          }
      });
}

function resetPassword(userId: string, email: string) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            'email': email
        })
    }

    return fetch(`${AUTH_SERVICE_URL}/account/${userId}/password/reset`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      }).catch(error => {
          console.log(error)
          return Promise.reject(error);
      });
}

function updatePassword(userId: string, updatedPassword: any) {
    if (updatedPassword) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'Content-Type':  'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(updatedPassword),
        }
        return fetch(`${AUTH_SERVICE_URL}/account/${userId}/password`, requestOptions)
          .then(handleResponse);
    }
}

function impersonateUser(userId: string) {
    let urlencoded = new URLSearchParams();
    const accessToken = getAccessToken();
    urlencoded.append("grant_type", 'urn:ietf:params:oauth:grant-type:token-exchange');
    urlencoded.append("subject_token", `${accessToken}`);
    urlencoded.append("requested_token_type", 'urn:ietf:params:oauth:token-type:access_token');
    urlencoded.append("client_id", process.env.REACT_APP_IDENTITY_CLIENT_ID);
    urlencoded.append("requested_subject", userId)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type':  'application/x-www-form-urlencoded',
        },
        body: urlencoded
    }
    return fetch(`${OIDC_URL}/token`, requestOptions)
      .then(handleResponse)
      .then(auth => {
          setAuth('orig_auth', AuthService.getAuth())
          setAuth('auth', auth)
          localStorage.setItem('impersonating', auth.id)
          return auth.id
      }).catch(error => {
          return Promise.reject(error);
      });
}

function stopImpersonating() {
    localStorage.setItem('auth', localStorage.getItem('orig_auth'))
    localStorage.removeItem('orig_auth')
    localStorage.removeItem('impersonating')
}

function getAccessToken(): string {
    const auth = AuthService.getAuth();
    if (auth) {
        return auth.access_token;
    }
    return null;
}

function getAuth() {
    let auth = localStorage.getItem('auth');
    if(!!auth && auth !== "null" && Object.keys(JSON.parse(auth)).length) {
        let authJson = JSON.parse(auth);
        if (!verifyAccessToken() && verifyRefreshToken()) {
            refreshToken(authJson.refresh_token).then(refreshAuth => {
                AuthService.setAuth('auth', refreshAuth)
                return refreshAuth;
            }).catch(err => {
                console.error(err);
                return null;
            })
        }
        return authJson;
    }
    return null;
}

function setAuth(authType: string, auth: any) {
    if (!!auth && auth !== "null") {
        addExtraToAuth(auth)
        const authString = JSON.stringify(auth);
        localStorage.setItem(authType, authString);
        authStore.setAuth(auth);
        const expiry = new Date();
        const expiresAt = expiry.getTime() + (auth.expires_in * 1000);
        localStorage.setItem('auth_expires', expiresAt);
        localStorage.setItem('auth_invalid', false);
    } else {
        logout();
    }
}

function verifyAccessToken() {
    const accessToken = JSON.parse(localStorage.getItem("auth"))?.access_token;
    return verifyJwt(accessToken);
}

function verifyRefreshToken() {
    const refreshToken = JSON.parse(localStorage.getItem("auth"))?.refresh_token;
    return verifyJwt(refreshToken);
}

function verifyJwt(token) {
    const decodedToken = jwt.decode(token, { complete: true })
    return !!decodedToken;

}

function addExtraToAuth(auth) {
    const payload = jwt.decode(auth.access_token, { complete: true }).payload
    auth['id'] = payload.sub
    auth['user_name'] = payload.preferred_username
    auth['email'] = payload.email
    auth['email_verified'] = payload.email_verified
    auth['name'] = payload.name
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
