import "./Profile.scss";
import {ProfileDetails, ProfilePassword, ProfilePicture} from ".";

const Profile = () => {
    return (
        <div className="content_main">
            <div className="form-profile">
                <ProfileDetails />
                <ProfilePicture />
                <ProfilePassword />
            </div>
        </div>
    )
}

export default Profile;
