import './App.scss';
import React from 'react';
import * as serviceWorker from './serviceWorker'
import {StateProvider} from './store/Store'
import LogRocket from 'logrocket'
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./components/Header/Header";
import {Routes} from "./routes/Routes";

function App() {
  if (process.env.ENABLE_LOG_ROCKET)
    LogRocket.init('lombb0/jajazz');

  return (
    <StateProvider>
        <Header />
        <Router>
          {Routes}
        </Router>
    </StateProvider>
  );
}

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
