import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { AuthService, UserService } from "../../service";

const ProfileDetails = () => {
    const { register, handleSubmit } = useForm();
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const auth = await AuthService.getAuth();
                const userProfile = await UserService.getUserProfile(auth?.user_name);
                setProfile(userProfile);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const onSubmit = async (updatedProfile) => {
        try {
            const userId = updatedProfile.userId;
            await UserService.updateUserProfile(userId, updatedProfile);
            setProfile(updatedProfile);
            setSuccess("Successfully updated profile details");
        } catch (err) {
            setError("Failed to update profile");
        }
    };

    return (
      <fieldset className="profile-content-border">
          <legend>Details</legend>
          {loading && <p>Loading profile...</p>}
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <form className="form-profile-content" onSubmit={handleSubmit(onSubmit)}>
              <label className="form-profile-label" htmlFor="inputFirstname">First Name</label>
              <input autoComplete="given-name" className="form-control ng-untouched ng-pristine ng-valid"
                     ref={register('firstname', {required: true})} id="inputFirstname"
                     defaultValue={profile?.firstName} type="text" required/>
              <label className="form-profile-label" htmlFor="inputLastname">Last Name</label>
              <input autoComplete="family-name" className="form-control ng-untouched ng-pristine ng-valid"
                     ref={register('lastname', {required: true})} id="inputLastname"
                     defaultValue={profile?.lastName} type="text" required/>
              <div className="form-profile-section-medium">
                  <label className="form-label">LMS Suggestions</label>
                  <label className="switch">
                      <input className="form-profile-medium-input ng-untouched ng-pristine ng-valid"
                             ref={register('lmsSuggestion')} defaultChecked={profile?.lmsSuggestion}
                             type="checkbox"/>
                      <span className="slider round"/>
                  </label>
              </div>
              <div className="form-profile-buttons">
                  <button className="btn btn-s">Update</button>
              </div>
          </form>
      </fieldset>
    );
};

export default ProfileDetails;
