import './Match.scss';
import {lazy, Suspense, useEffect, useState} from "react";

const MatchDetails = lazy(() => import('./MatchDetails'));

const Match = ({match}) => {
  const [collapsableSpan, setCollapsableSpan] = useState("collapsable collapsable-plus");
  const [showComponent, setShowComponent] = useState(false);
  // const date = new Date(match.timeTS);
  // const hours = "0" + date.getHours();
  // const minutes = "0" + date.getMinutes();
  // const formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);
  const formattedTime = new Date(match.timeTS).toUTCString().substr(17, 5);
  const homeImg = "https://images.fotmob.com/image_resources/logo/teamlogo/" + match.home.id + "_xsmall.png";
  const awayImg = "https://images.fotmob.com/image_resources/logo/teamlogo/" + match.away.id + "_xsmall.png";
  const scoreStatus = (match.status.started)
    ? match.status.scoreStr
    : formattedTime;
  let timeStatus = '';
  let scoreStatusClass = 'match-status_score-active';
  let timeStatusClass = '';

  if (match.status.cancelled) {
    timeStatus = 'PP';
    timeStatusClass = 'time-status-cancelled';
    scoreStatusClass = 'match-status_score-active score-status-cancelled';
  } else if (match.status.finished) {
    timeStatus = 'FT';
    timeStatusClass = 'time-status-finished';
  } else if (match.status.ongoing) {
    timeStatus = match.status.liveTime.short;
    timeStatusClass = 'blink';
  }

  useEffect(() => {
    const collapsableSpanClass = showComponent ? 'collapsable-minus' : 'collapsable-plus'
    setCollapsableSpan(`collapsable ${collapsableSpanClass}`);
  }, [showComponent]);

  return (
    <>
      <div className="livescores_match" onClick={() => setShowComponent(!showComponent)}>
        <div className="livescores_match_hometeam">
          <span className="livescores_match_teamname">{match.home.name}</span>
          <div className="livescores_logo">
            <div className="image image-loading">
              <img src={homeImg} className="image_wrapper team-logo team-logo-small"
                   height={20}
                   width={20}
                   alt={match.home.name}/>
            </div>
          </div>
        </div>
        <div className="livescores_match_status">
          <span className={scoreStatusClass}>{scoreStatus}</span>
          <span className="match-status_score-active">
                        <div className={timeStatusClass}>{timeStatus}</div>
                    </span>
        </div>
        <div className="livescores_match_awayteam">
          <div className="livescores_logo">
            <div className="image image-loading">
              <img src={awayImg} className="image_wrapper team-logo team-logo-small"
                   height={20}
                   width={20}
                   alt={match.away.name}/>
            </div>
          </div>
          <span className="livescores_match_teamname">{match.away.name}</span>
        </div>
        <span className={collapsableSpan} />
      </div>
      {showComponent && (
        <Suspense fallback={<div className="livescores_no-fixtures">Fetching Match Details...</div>}>
          <MatchDetails matchId={match.id} />
        </Suspense>
      )}
    </>
  )
}

export default Match;
