import "./Fixtures.scss"
import {useEffect, useRef, useState} from 'react';
import GroupedMatches from "../Match/GroupMatches";
import {FixtureService} from "../../service";


const Fixtures = () => {
    const [date, setDate] = useState();
    const [dateDiff, setDateDiff] = useState(0);
    const [fixtures, setFixtures] = useState();
    const timeoutRef = useRef(null);

    useEffect(() => {
        function setInitialDate() {
            const today = new Date();
            const year = today.getFullYear(),
                month = today.getMonth(),
                day = today.getDate();
            const date = new Date(year, month, day);
            setDate(date);
        }
        setInitialDate();
    }, []);

    useEffect(() => {
        function setDateOnUpdate() {
            const today = new Date();
            const year = today.getFullYear(),
                month = today.getMonth(),
                day = (today.getDate() + dateDiff);
            const date = new Date(year, month, day);
            setDate(date);
        }
        setDateOnUpdate();
    }, [dateDiff]);

    useEffect(() => {
        function getDateForFixtures() {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            return ye + mo + da;
        }

        async function updateFixtures() {
            const dateForFixtures = getDateForFixtures();
            const fixtures = await FixtureService.getFixtures(dateForFixtures);
            setFixtures(fixtures);
        }
        async function getFixtures() {
            updateFixtures();
            timeoutRef.current = setInterval(async () => {
                updateFixtures();
            }, 60000);
        }
        if (date) {
            clearInterval(timeoutRef.current);
            getFixtures();
        }
    }, [date]);

    function updateDate(diff: number) {
        setDateDiff(dateDiff + diff);
    }

    return (
        <div className="fixture-list">
            {date &&
                <div className="fixture-date-tabs">
                    <div className="date_change_buttons">
                        <button type="button" className="btn-link-item previous-date" onClick={() => updateDate(-1)}>
                            <span className="previous_element">‹</span>
                        </button>
                        <button type="button" className="btn-link-item next-date"  onClick={() => updateDate(1)}>
                            <span className="next_element">›</span>
                        </button>
                        <div className="fixture-date">
                            {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit"
                            }).format(date)}
                        </div>
                    </div>
                </div>
            }
            {fixtures && fixtures.map((fixture, index) =>
                <GroupedMatches key={index} fixture={fixture} index={index}/>
            )}
        </div>
    )
}

export default Fixtures
