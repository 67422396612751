import "./RuleSelection.scss";
import assistIcon from "../../assets/images/selection-assist.svg";
import goalIcon from "../../assets/images/selection-goal.svg";
import redCardIcon from "../../assets/images/selection-red_card.svg";
import yellowCardIcon from "../../assets/images/selection-yellow_card.svg";
import {useEffect, useRef, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const RuleSelectIcon = ({toggleSelection, player, rule, selections, matchId, user, isOldMatch }) => {
    const [iconClassName, setIconClassName] = useState("select-icon unselected-fill");
    const selected = useRef();

    let icon = null
    let selectedBy = selections?.filter(selection => selection.playerId === player.id && selection.ruleId === rule.ruleId);

    switch (rule.type) {
        case 'assist':
            icon = assistIcon
            break;
        case 'goal':
            icon = goalIcon
            break;
        case 'red':
            icon = redCardIcon
            break;
        case 'yellow':
            icon = yellowCardIcon
            break;
        default:
            break;
    }

    useEffect(() => {
        function setSelectedData() {
            if (selectedBy.length > 0) {
                if (selectedBy.some(selection => selection.userId === user)) {
                    setIconClassName("select-icon selected-fill");
                    selected.current = "current";
                } else {
                    setIconClassName("select-icon pre-selected-fill");
                    selected.current = "other";
                }
            } else {
                setIconClassName("select-icon unselected-fill");
                selected.current = null;
            }
        }
        if (selections) {
            setSelectedData();
        }
    }, [selections, selectedBy, user])

    function isCurrentUserSelection() {
        return selectedBy.some(selection => selection.userId === user);
    }

    function toggle() {
        if (!isOldMatch) {
            if (isCurrentUserSelection()) {
                selected.current = null;
            } else {
                selected.current = "current";
            }
            toggleSelection(selectedBy, matchId, rule, player);
        }
    }

    const TooltipContext = props => (
        <Tooltip {...props} className="selection-tooltip">
            {selectedBy && selectedBy.map(selected => (
                <div className="selection-user">{selected.userId}</div>
            ))}
            {!isCurrentUserSelection() && (
                <div className="selection-action" title="Select" onClick={toggle}>&#10004;</div>
            )}
            {isCurrentUserSelection() && (
                <div className="selection-action" title="Unselect" onClick={toggle}>&#10008;</div>
            )}
        </Tooltip>
    );

    return (
        <div className="ng-star-inserted">
            <div className="lineup_rule-item">
                {selected.current && (selected.current === "other" || (selected.current === "current" && selectedBy.length > 1)) &&
                    <OverlayTrigger trigger="click" placement="top" overlay={TooltipContext}>
                        <button className="selection-icon" >
                            {icon && (
                                <img alt="" className={iconClassName} title="selected" src={icon}/>
                            )}
                        </button>
                    </OverlayTrigger>
                }
                {(!selected.current || (selected.current === "current" && selectedBy.length <= 1)) &&
                    <button className="selection-icon" onClick={toggle}>
                        {icon && (
                            <img alt="" className={iconClassName} title={selectedBy.length > 0 ? 'selected' : 'unselected'} src={icon}/>
                        )}
                    </button>
                }
            </div>
        </div>
    )
}

export default RuleSelectIcon;
