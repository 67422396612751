import {useContext, useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import {TabContent, TabLink, Tabs} from "react-tabs-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import LmsSelection from "../LmsSelection/LmsSelection";
import LmsStatus from "../LmsStatus/LmsStatus";
import {PredictorService} from "../../service/PredictorService";
import {AuthService} from "../../service/AuthService";
import {FixtureService} from "../../service";
import {store} from '../../store/Store';

const LmsDetails = () => {
  const {state, dispatch} = useContext(store);
  const [competition, setCompetition] = useState({});
  const [competitionCode, setCompetitionCode] = useState();
  const [eliminated, setEliminated] = useState(true);
  const [token, setToken] = useState();
  const location = useLocation();

  let competitionId = useRef(0);

  useEffect(() => {
    async function setStateValues() {
      const auth = await AuthService.getAuth();
      const now = new Date();
      if (!state.teams.length || !state.expiry || state.expiry < now) {
        FixtureService.getTeams().then(teams => {
          console.log(teams)
          dispatch({type: "SET_EXPIRY", payload: now.getTime() + 86400000});
          dispatch({type: "SET_TEAMS", payload: teams});
        });
      }
      if (!state.gameweek) {
        FixtureService.getCurrentGameweek().then(currentGameweek => {
          dispatch({type: "SET_GAMEWEEK", payload: currentGameweek})
        })
      }
      return auth;
    }
    if (!!state) {
      setStateValues().then(setToken);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    competitionId.current = parseInt(searchParams.get('id'));
    function fetchData() {
      PredictorService.getCompetitionDetails(competitionId.current).then(competition => {
        setCompetition(competition);
        setCompetitionCode(competition?.code);
      });
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    async function fetchData() {
      const isEliminated = await PredictorService.checkIfEliminated(competitionId.current, competition?.round);
      setEliminated(isEliminated);
    }
    fetchData();
  }, [competition?.round]);

  async function regenerateCode() {
    const newCode = await PredictorService.regenerateCode(competition.competitionId);
    setCompetitionCode(newCode);
  }

  return (
    <div className="content_main">
      <fieldset className="form-competition-content-border">
        <legend>{competition?.name}</legend>
        <section className="competition_group">
          {token?.user_name === competition?.owner && (
            <h4 className="competition_group_title">
              <div className="image competition_group_flag image-loading">
                <div className="competition_match_teamname">
                  (Code: {competitionCode})
                  <FontAwesomeIcon className="redo-icon" icon={faRedo} onClick={regenerateCode} />
                </div>
              </div>
            </h4>
          )}
          <Tabs className="competition-tabset">
            <ul className="nav nav-tabs justified-content-start">
              <li className="nav-item">
                <TabLink to="selection">Selection</TabLink>
              </li>
              <li className="nav-item">
                <TabLink to="status">Status</TabLink>
              </li>
            </ul>

            <TabContent for="selection">
              {!!competition && (
                <LmsSelection competitionId={competitionId.current}
                              round={competition.round}
                              eliminated={eliminated}/>
              )}
            </TabContent>
            <TabContent for="status">
              {!!competition && !!state.gameweek && (
                <LmsStatus competition={competition}
                           token={token}
                           gameweek={state.gameweek}/>
              )}
            </TabContent>
          </Tabs>
        </section>
      </fieldset>
    </div>
  )
}

export default LmsDetails
