import './Header.scss';
import Logo from '../../assets/images/icon.svg';

const HeaderLogo = () => {
    return (
        <div className="header_logo">
            <a className="icon_header" href="/" >
                <img alt="Ja Jazz" className="title_icon" src={Logo} />
                <div className="title_logo">Ja Jazz</div>
            </a>
        </div>
    )
}

export default HeaderLogo;
