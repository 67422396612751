import "./Login.scss";
import React, {useContext, useState} from "react";
import {AuthService, UserService} from "../../service";
import {useHistory} from 'react-router-dom';
import {store} from "../../store/Store";

const Login = () => {
    const {dispatch} = useContext(store);
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        AuthService.login(username, password).then(
            (auth) => {
                if (!!auth) {
                    const profilePic = UserService.getUserProfilePic(auth.user_name)
                    const profile = UserService.getUserProfile(auth.user_name)
                    Promise.all([profilePic, profile]).then(values => {
                        const profile = values[1]
                        profile['profilePic'] = values[0]
                        dispatch({type: "SET_PROFILE", payload: JSON.stringify(profile)});
                    })
                    history.push('/competition');
                }
            },
            (error) => console.log(error)
        );
    };

    return (
        <div className="content_main">
            <div className="login-content">
                <form className="form-signin">
                    <div className="form-signin-content">
                        <div className="login-register">
                            Not already signed up? <a href="/register">register</a>
                        </div>
                        <div className="login-separator">------------    OR    ------------</div>
                        <div className="login-form">
                            <label htmlFor="inputUsername" className="form-label">Username</label>
                            <input name="username" type="text" id="inputUsername" className="form-control"
                                   placeholder="Username" onChange={onChangeUsername} required autoFocus />
                            <label htmlFor="inputPassword" className="form-label">Password</label>
                            <input name="password" type="password" id="inputPassword" className=" form-control"
                                   placeholder=" Password" onChange={onChangePassword} required />
                            <div className="login-reset">
                                Forgot password?
                                <a className="reset-password" href="/reset"> Reset</a>
                            </div>
                            <button className="btn btn-lg btn-primary btn-block" onClick={handleLogin} type="button">
                                Sign in
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
