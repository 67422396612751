import "./ScorePredictorFixture.scss";
import {useEffect, useState} from "react";
import {PredictorService} from "../../service";
import ScorePredictorUserSelection from "./ScorePredictorUserSelection";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ScorePredictorFixture = ({activeGameweek, userId, competitionId, fixture, gameweekSelections, teamList}) => {
  const [homeTeamScore, setHomeTeamScore] = useState(undefined);
  const [awayTeamScore, setAwayTeamScore] = useState(undefined);
  const [homeTeamName, setHomeTeamName] = useState('');
  const [awayTeamName, setAwayTeamName] = useState('');
  const [homeTeamClass, setHomeTeamClass] = useState('badge badge-home');
  const [awayTeamClass, setAwayTeamClass] = useState('badge badge-away');
  const [updatedSuccess, setUpdatedSuccess] = useState('');
  const [otherUsersSelection, setOtherUsersSelection] = useState();

  useEffect(() => {
    function updateTeamDetails() {
      const homeTeam = teamList.filter(team => team.id === fixture.teamH)[0].name;
      const awayTeam = teamList.filter(team => team.id === fixture.teamA)[0].name;
      setHomeTeamName(homeTeam);
      setAwayTeamName(awayTeam);
      setHomeTeamClass(`badge badge-home ${homeTeam}`);
      setAwayTeamClass(`badge badge-away ${awayTeam}`);
    }
    if (!!fixture && !!gameweekSelections) {
      const fixtureSelection = gameweekSelections.filter(sel => sel.matchId === fixture.id);
      const userScore = fixtureSelection.find(score => score.userId === userId);
      setOtherUsersSelection(fixtureSelection.filter(score => score.userId !== userId));
      if (!!userScore) {
        setHomeTeamScore(userScore.homeScore)
        setAwayTeamScore(userScore.awayScore)
      }
      if (!!teamList?.length) {
        updateTeamDetails()
      }
    }
  }, [fixture, gameweekSelections, teamList, userId]);

  function updateScore(type, value) {
    const score = {
      'matchId': fixture.id,
      'competitionId': competitionId,
      'gameweek': activeGameweek,
      'homeScore': homeTeamScore,
      'awayScore': awayTeamScore
    }
    switch (type) {
      case 'home':
        setHomeTeamScore(Number(value))
        score.homeScore = Number(value)
        break
      case 'away':
        setAwayTeamScore(Number(value))
        score.awayScore = Number(value)
        break
      default:
        break
    }
    if (score.homeScore !== undefined && score.homeScore >= 0 && score.awayScore !== undefined &&score.awayScore >= 0) {
      if(!fixture.started) {
        PredictorService.updateScore(score)
          .then(() => {
            console.log('Successfully updated score')
            setUpdatedSuccess('true')
            setTimeout(() => setUpdatedSuccess(null),5000)
          })
          .catch(() => {
            console.error('Failed to update score')
            setUpdatedSuccess('false')
            setTimeout(() => setUpdatedSuccess(null),5000)
          });
      }
    }
  }

  return (
    <li key={fixture.id}>
      <div className="gameweek_match">
        <div className="gameweek_match_hometeam">
          <span className="gameweek_match_teamname">{homeTeamName}</span>
          <span className={homeTeamClass} />
        </div>
        <div className="gameweek_match_scores">
          <input
            type='number'
            className='gameweek_match_home-score'
            pattern='[0-9]{0,2}'
            value={!homeTeamScore && homeTeamScore !== 0 ? '' : homeTeamScore}
            onChange={(e) => updateScore('home', e.target.value)}
            readOnly={fixture.started}
          />
          <input
            type='number'
            className='gameweek_match_away-score'
            pattern='[0-9]{0,2}'
            value={!awayTeamScore && awayTeamScore !== 0 ? '' : awayTeamScore}
            onChange={(e) => updateScore('away', e.target.value)}
            readOnly={fixture.started}
          />
        </div>
        <div className="gameweek_match_awayteam">
          <span className={awayTeamClass} />
          <span className="gameweek_match_teamname">{awayTeamName}</span>
        </div>
        {updatedSuccess === 'true' &&(
          <div className="gameweek_match_updated">
            <FontAwesomeIcon icon={faCheck} className="home-team-selection" color="green"/>
          </div>
        )}
        {updatedSuccess === 'false' &&(
          <div className="gameweek_match_updated">
            <FontAwesomeIcon icon={faTimes} className="home-team-selection" color="red"/>
          </div>
        )}
      </div>
      {!!fixture &&
        <ScorePredictorUserSelection
          otherUsersSelection={otherUsersSelection}
          homeTeamName={homeTeamName}
          awayTeamName={awayTeamName}
          fixture={fixture}
        />
      }
    </li>
  )
}

export default ScorePredictorFixture
