import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBaby, faClipboardList, faExclamationTriangle, faIdBadge, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from 'react-router-dom';
import {useContext, useEffect, useState} from "react";
import {AuthService, FixtureService, PredictorService} from "../../service";
import {store} from "../../store/Store";

const CompetitionListItem = ({competition}) => {

    const {state, dispatch} = useContext(store);
    const [token, setToken] = useState({});
    const [isDouble, setIsDouble] = useState(false);
    const [match, setMatch] = useState();
    const [userStatus, setUserStatus] = useState();
    const [lmsSelections, setLmsSelections] = useState([]);
    const [isSelectionMade, setIsSelectionMade] = useState(false);
    const history = useHistory();
    const handleClick = () => {
        history.push(`/competition/${competition.type}?id=${competition.competitionId}`);
    }
    const deleteCompetition = () => PredictorService.deleteCompetition(competition.competitionId);

    useEffect( ()=> {
        async function fetchData() {
            const auth = await AuthService.getAuth()
            setToken(auth)
        }
        fetchData();
    },[]);

    useEffect(() => {
        async function getUserStatus() {
            const userStatus = await PredictorService.getUserStatus(competition.competitionId, competition.round);
            const lmsSelections = await PredictorService.getMyLmsSelections(competition.competitionId, competition.round);
            setUserStatus(userStatus);
            setLmsSelections(lmsSelections);
        }

        async function getPredictorDetails() {
            if (!Object.keys(state.teamFixtures).length) {
                const fixtures = await FixtureService.getTeamFixtures(competition?.teamId);
                dispatch({type:"TEAM_FIXTURES", payload: fixtures})
            }
            const firstActiveIndex = state.teamFixtures?.fixturesTab?.fixtureList.findIndex(fixture => fixture.status.finished === false);
            setMatch(state.teamFixtures?.fixturesTab?.fixtureList[firstActiveIndex]);
        }
        if (competition?.type === 'lms') {
            getUserStatus()
        } else if (competition?.type === 'predictor') {
            getPredictorDetails()
        }
    }, [competition, dispatch, state.teamFixtures]);

    useEffect(() => {
        async function fetchData() {
            const isDouble = await PredictorService.hasDoublePoints(competition?.competitionId, match?.id);
            setIsDouble(isDouble);
        }
        fetchData();
    }, [match, competition?.competitionId])

    useEffect(() => {
        if (!!lmsSelections) {
            setIsSelectionMade(lmsSelections.filter(selection => selection.gameweek === parseInt(state.gameweek)).length > 0);
        }
    }, [lmsSelections, state.gameweek])

    return (
        <section className="competition-selection_competition-section card" onClick={handleClick}>
            <h4 className="competition-selection_competition-title">
                {competition && (
                    <div className="competition-selection_competition-item">
                        {competition.name}
                        {competition.owner === token?.user_name && (
                            <FontAwesomeIcon className="right" icon={faTrashAlt} onClick={deleteCompetition}/>
                        )}
                        {competition && competition.type === 'predictor' && (
                            <div className="competition-badge">
                                <FontAwesomeIcon icon={faIdBadge} size="3x" />
                            </div>
                        )}
                        {competition && competition.type === 'lms' && (
                            <div className="competition-badge">
                                <FontAwesomeIcon icon={faBaby} size="3x" />
                            </div>
                        )}
                        {competition && competition.type === 'score' && (
                            <div className="competition-badge">
                                <FontAwesomeIcon icon={faClipboardList} size="3x" />
                            </div>
                        )}
                    </div>
                )}
                

                {competition.owner === token?.user_name && (
                    <div className="competition-selection_competition-code">
                        (Code: {competition.code})
                    </div>
                )}
                {competition && userStatus && competition.type === 'lms' && (
                    <div className="competition-selection_competition-lives text-success ng-star-inserted">
                        (Lives: {userStatus.lives} / {competition.lives})
                    </div>
                )}
            </h4>

            {competition && competition.type === 'predictor' && (
                <div className="ng-star-inserted">
                    {isDouble && (
                        <div className="warning">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            Double Points week
                        </div>
                    )}
                </div>
            )}

            <div className="ng-star-inserted">
                {!isSelectionMade && !!userStatus && !userStatus.eliminated && (
                    <div className="warning">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        No Selections Made Yet
                    </div>
                )}
                {userStatus && userStatus.eliminated && (
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        You have been eliminated
                    </div>
                )}
            </div>
        </section>
    )
}

export default CompetitionListItem;
