import "./ResetPassword.scss";
import * as React from "react";
import {useEffect, useState} from "react";
import {AuthService} from "../../service";

const ResetPassword = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setErrorMessage();
        }, 10e3);

        return () => window.clearTimeout(timer);
    }, [errorMessage]);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setSuccessMessage();
        }, 10e3);

        return () => window.clearTimeout(timer);
    }, [successMessage]);

    const resetPassword = (e) => {
        e.preventDefault();
        AuthService.resetPassword(username, email)
          .then(() => setSuccessMessage("Password Reset email sent"))
          .catch(error => setErrorMessage(error))
    }

    return (
        <div className='content_main'>
            <div className="reset-content">
                <form className="form-reset">
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    <div className="form-reset-content">
                        <label htmlFor="inputUsername" className="form-label">Username</label>
                        <input name="username" type="text" id="inputUsername" className="form-control"
                               placeholder="Username" onChange={onChangeUsername} required autoFocus />
                        <label htmlFor="inputEmail" className="form-label">Email</label>
                        <input name="email" type="email" id="inputEmail" className=" form-control"
                               placeholder="Email" onChange={onChangeEmail} required />
                        <button className="btn btn-lg btn-primary btn-block" onClick={resetPassword} type="button">
                            Reset
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;
