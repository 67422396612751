import * as React from 'react'
import type {RouteProps} from 'react-router-dom'
import {Redirect, Route, RouteComponentProps} from 'react-router-dom'
import {AuthService} from "../service";

interface PrivateRouteParams extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>
}

export function PrivateRoute({component: Component, ...rest}: PrivateRouteParams) {

    return (
        <Route {...rest} render={props => {
          return AuthService.verifyAccessToken()
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location},}}/>
        }} />
    )
}
