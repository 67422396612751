import {useEffect, useState} from "react";
import {AuthService, UserService} from "../../service";
import {useForm} from "react-hook-form";

const ProfilePicture = () => {
    const [token, setToken] = useState();
    const {register, handleSubmit} = useForm();
    const [profilePic, setProfilePic] = useState();
    const [profileSrc, setProfileSrc] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    useEffect(() => {
        async function setAuthToken() {
            const auth = await AuthService.getAuth();
            setToken(auth);
        }
        setAuthToken();
    }, []);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setSuccessMessage();
        }, 3e3);

        return () => window.clearTimeout(timer);
    }, [successMessage]);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setErrorMessage();
        }, 3e3);

        return () => window.clearTimeout(timer);
    }, [errorMessage]);

    const onFileSelected = (evt) => {
        setProfilePic(evt.target.files[0]);
        setProfileSrc(URL.createObjectURL(evt.target.files[0]));
    }

    const upload = pictures => {
        const picture = pictures.profilePic[0];
        if (picture) {
            if (picture.size < 100000) {
                const userId = token?.user_name;
                UserService.uploadProfilePic(userId, picture).then(() => {
                    setSuccessMessage("Successfully update profile pic");
                    // UserService.getUserProfile(userId).then(profile => localStorage.setItem('profile', profile));
                }).catch(() => {
                    setErrorMessage('Failed to update profile picture');
                });
            } else {
                const fileSize = Math.round((picture.size / 1024) * 10 ) / 10;
                setErrorMessage(`File size of ${fileSize}Kb is too big, max size is 100Kb`);
            }
        }
    }

    return (
        <fieldset className="profile-content-border">
            <legend>Picture</legend>
            <form className="form-profile-picture" onSubmit={handleSubmit(upload)}>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <div className="info">(Types: [.jpg, .png], Max Size: 100Kb)</div>
                <div className="input-file-container">  
                    <input className="input-file" id="profilePic" type="file" accept=".jpg,.png"
                           ref={register('profilePic')} onChange={onFileSelected}/>
                    <label tabIndex="0" htmlFor="profilePic" className="input-file-trigger">Select a file...</label>
                </div>
                {profilePic &&
                <div className="profilePicDetails">
                    <img className="previewImage" alt="" src={profileSrc}/>
                    <div className="chosenFile">{profilePic.name}</div>
                </div>
                }
                <div className="form-profile-buttons">
                    <button className="btn btn-s">Upload</button>
                </div>
            </form>
        </fieldset>
    )
}

export default ProfilePicture;
