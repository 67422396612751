import {AuthService} from "./AuthService";

export const PredictorService = {
    addSelection,
    checkIfEliminated,
    createComp,
    deleteCompetition,
    getCompetitions,
    getCompetitionDetails,
    getMyLmsSelections,
    getMySelections,
    getResults,
    getRoundStatus,
    getRules,
    getSelections,
    getGameweekScoreSelection,
    getUserStatus,
    hasDoublePoints,
    joinCompetition,
    regenerateCode,
    removeSelection,
    removeLmsSelection,
    resetCompetitions,
    restartCompetition,
    toggleSelection,
    toggleDoublePoints,
    updateRules,
    updateSelection,
    updateScore,
}

const PREDICTOR_SERVICE_URL: string = process.env.REACT_APP_PREDICTOR_SERVICE_URL;

function getHeaders() {
    const headers = {
        'Content-Type':  'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
    return headers;
}

function addSelection(competitionId: number, matchId: number, selection: any): any {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(selection),
    };
    if (competitionId && matchId && selection) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/match/${matchId}`, requestOptions);
        return response.then(details => details).catch(error => {
            throw new Error(error)
        });
    }
}

function getCompetitions(): any {

    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition`, requestOptions);
    return response.then(details => details).catch(error => console.error(error));
}

function checkIfEliminated(competitionId: number, round: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && round) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/round/${round}/status/eliminated`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function createComp(comp: any) {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(comp),
    };
    if (comp) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function deleteCompetition(compId: number): any {
    const requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (compId) {
        return doFetch(`${PREDICTOR_SERVICE_URL}/competition/${compId}`, requestOptions);
    }
}

function getMySelections(competitionId: number, matchId: number): any {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && matchId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/match/${matchId}/selection/me`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getMyLmsSelections(competitionId: number, round: number): any {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && round) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/round/${round}/me`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getCompetitionDetails(competitionId: number): any {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getUserStatus(competitionId: number, round: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && round) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/round/${round}/me`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}
function getResults(id: number): any {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (id) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${id}/result`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getRoundStatus(competitionId: number, round: number, gameweek: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && round) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/round/${round}/${gameweek}`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getRules(competitionId: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/rule`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getSelections(competitionId: number, matchId: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && matchId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/match/${matchId}`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function getGameweekScoreSelection(competitionId: number, gameweek: number) {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (!!competitionId && !!gameweek) {
        return doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/score/gameweek/${gameweek}`, requestOptions);
    }
}

function toggleSelection(competitionId: number, matchId: number, selection: any): any {
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(selection),
    };
    if (competitionId && matchId && selection) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/match/${matchId}`, requestOptions);
        return response.then(details => details);
    }
}

function hasDoublePoints(competitionId: number, matchId: number): boolean {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && matchId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/match/${matchId}/double`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function joinCompetition(code: string) {
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (code) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/join/${code}`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function regenerateCode(competitionId: number) {

    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/newcode`, requestOptions);
        return response.then(details => details);
    }
}

function removeSelection(competitionId: number, selectionId: number): any {
    const requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        withCredentials: true,
    };

    if (competitionId && selectionId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/${selectionId}`, requestOptions);
        return response.then(details => details);
    }
}

function removeLmsSelection(competitionId: number, gameweek: number, selectionId: number): any {

    const requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        withCredentials: true,
    };
    if (competitionId && gameweek && selectionId) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/selection/gameweek/${gameweek}/${selectionId}`, requestOptions);
        return response.then(details => details);
    }
}

function resetCompetitions() {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
    };
    const response = doFetch(`${PREDICTOR_SERVICE_URL}/admin/reset`, requestOptions);
    return response.then(details => details).catch(error => console.error(error));
}

function restartCompetition(competitionId: number, userIds: string[]) {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(userIds),
    };
    const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/restart`, requestOptions);
    return response.then(details => details).catch(error => console.error(error));
}

function toggleDoublePoints(competitionId: number, matchId: number): any {

    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
    };

    if (competitionId && matchId) {
        return doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/match/${matchId}/double`, requestOptions);
    }
}

function updateRules(competitionId: number, rules: any) {
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(rules),
    };
    if (competitionId !== undefined && rules !== undefined) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${competitionId}/rule`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function updateSelection(selection: any) {
    const requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(selection),
    };
    if (selection !== undefined) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${selection.competitionId}/selection/gameweek/${selection.gameweek}`, requestOptions);
        return response.then(details => details).catch(error => console.error(error));
    }
}

function updateScore(score: any) {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        withCredentials: true,
        body: JSON.stringify(score),
    };
    if (score !== undefined) {
        const response = doFetch(`${PREDICTOR_SERVICE_URL}/competition/${score.competitionId}/score`, requestOptions);
        return response;
    }
}

async function doFetch(url, options) {
    if (!!AuthService.getAuth()) {
        options.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`
    }
    let fetchResponse = await fetch(url, options);
    // If first call fails with 401 retry else logout
    if (401 === fetchResponse.status) {
        localStorage.setItem('auth_invalid', true);
        const authResponse = AuthService.getAuth(url, options);
        options['headers']['Authorization'] = `Bearer ${authResponse.access_token}`;
        fetchResponse = await fetch(url, options);
    }
    return handleResponse(fetchResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (401 === response.status) {
                console.log('Unauthorized need to update token', response);
                AuthService.logout(true)
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
