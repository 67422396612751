import "./Player.scss";
import RuleSelectIcon from "../RuleSelectIcon/RuleSelectIcon";
import field from "../../assets/images/pitch.svg";
import substitute from "../../assets/images/substitution.svg";
import {useEffect, useState} from "react";

const Player = ({toggleSelection, rules, player, selections, matchId, user, isOldMatch, isFutureMatch, showOnlyLineup}) => {

    const [lineupClass, setLineupClass] = useState();

    let icon = null
    let title = "Not Selected"

    switch (player.lineupClass) {
        case 'starting-lineup':
            icon = field
            title = "Starting"
            break;
        case 'substitute':
            icon = substitute
            title = "Substitute"
            break;
        default:
            break;
    }

    useEffect(() => {
        setLineupClass((showOnlyLineup && !player.lineupClass) ? "hidden" : "")
    }, [showOnlyLineup]);

    return (
        <div className={`lineup_detail ${lineupClass}`}>
            <div className="lineup_list-item">
                <div className="lineup_item-detail">
                    <div className="lineup_player">
                        <div className={`lineup_player-name`}> {player.name}
                            {icon && (
                              <img alt="" className={`lineup_player-lineup-icon lineup-status`}
                                   title={`${title}`} src={icon}/>
                            )}
                        </div>
                    </div>
                    <div className="lineup_player-rule-items">
                        {!isFutureMatch && !!selections && rules?.map((rule, index) =>
                            <RuleSelectIcon key={index} toggleSelection={toggleSelection}
                                            player={player} rule={rule} selections={selections}
                                            matchId={matchId} user={user} isOldMatch={isOldMatch}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Player;
