import React from 'react';
import './Header.scss';
import HeaderLogo from "./HeaderLogo";
import HeaderLogin from "./HeaderLogin";
import HeaderMenu from "./HeaderMenu";

const Header = () => {

    return (
        <div className="header_container">
            <section className="header_content">
                <HeaderLogo />
                <HeaderLogin />
                <HeaderMenu />
            </section>
        </div>
    )
}

export default Header;
