import './Match.scss';
import Match from "./Match";
import {Suspense, useEffect, useState} from "react";

const GroupedMatches = ({fixture, index}) => {
  const imgUrl = `https://images.fotmob.com/image_resources/logo/teamlogo/${fixture.ccode.toLowerCase()}.png`;
  const [collapsableSpan, setCollapsableSpan] = useState("collapsable collapsable-closed");
  const [showComponent, setShowComponent] = useState(index === 0);

  useEffect(() => {
    const collapsableSpanClass = showComponent ? 'collapsable-opened' : 'collapsable-closed'
    setCollapsableSpan(`collapsable ${collapsableSpanClass}`);
  }, [showComponent]);

  useEffect(() => {
    function expandFirst() {
      setCollapsableSpan("collapsable collapsable-opened");
    }
    if (index === 0) {
      expandFirst()
    }
  }, [index]);

  return (
    <div className="livescores_group">
      <div className="livescores_group_title" onClick={() => setShowComponent(!showComponent)}>
        <img src={imgUrl}
             className="image_wrapper team-logo team-logo-small"
             height={20}
             width={20}
             alt={fixture.name}/>
        <span className="livescores_group_competition">{fixture.name}</span>
        <span className={collapsableSpan} />
      </div>
      {showComponent && (
        <Suspense fallback={<div className="livescores_no-fixtures">Fetching Match Details...</div>}>
          {fixture && fixture.matches.map((match) =>
            <Match key={match.id} match={match} />
          )}
        </Suspense>
      )}
    </div>
  )
}

export default GroupedMatches;
