import "./LineupOnlyToggle.scss"

const LineupOnlyToggle = ({toggleFunction, currentState}) => {

  return (
    <>
      <label className="form-label lineup">Lineup</label>
      <label className="switch">
        <input type="checkbox" className="competition-detail_medium-input" checked={currentState}
               onChange={() => toggleFunction()}/>
        <span className="slider round"/>
      </label>
    </>
  )
}

export default LineupOnlyToggle;
