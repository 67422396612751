import * as React from "react";

const Clear = () => {

  localStorage.removeItem('teams');
  localStorage.removeItem('expiry');
  localStorage.removeItem('teamFixtures');
  localStorage.removeItem('gameweek');

  if('caches' in window){
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      })
    });
  }

  return (
    <div className="content_main">
      <div>Cache cleared <a href={"/"}>go home</a></div>
    </div>
  )
}

export default Clear;
