import "./DoublePoints.scss"
import {useEffect, useState} from "react";
import {PredictorService} from "../../service";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DoublePoints = ({competitionId, matchId, owner, user}) => {

  const [double, setDouble] = useState(false);

  function toggleDoublePoints() {
    PredictorService.toggleDoublePoints(competitionId, matchId).then(() => {
      setDouble(!double);
    })
  }

  useEffect(() => {
    async function fetchData() {
      PredictorService.hasDoublePoints(competitionId, matchId).then(isDouble => {
        setDouble(!!isDouble)
      })
    }
    fetchData();
  }, [competitionId, matchId]);

  return (
    <>
      {double && owner !== user && (
        <label className="form-label double-points">
          <FontAwesomeIcon icon={faCheckDouble}/>Double Points
        </label>
      )}
      {owner === user && matchId && (
        <>
          <label className="form-label double-points">Double Points</label>
          <label className="switch">
            <input className="competition-detail_double-points-input" type="checkbox" checked={double}
                   onChange={toggleDoublePoints} />
            <span className="slider round" />
          </label>
        </>
      )}
    </>
  )
}

export default DoublePoints;
