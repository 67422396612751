import {AuthService} from "../../service";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";

const ProfilePassword = () => {
    const [token, setToken] = useState();
    const {errors, register, handleSubmit, setError} = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    useEffect(() => {
        async function setAuthToken() {
            const auth = await AuthService.getAuth();
            setToken(auth);
        }
        setAuthToken();
    }, []);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setErrorMessage();
        }, 3e3);

        return () => window.clearTimeout(timer);
    }, [errorMessage]);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setSuccessMessage();
        }, 3e3);

        return () => window.clearTimeout(timer);
    }, [successMessage]);

    const onSubmit = updatedPassword => {
        if (validateNewPassword(updatedPassword)) {
            AuthService.updatePassword(token?.user_name, updatedPassword)
                .then(() => setSuccessMessage("Successfully updated password"))
                .catch(() => setErrorMessage("Failed to update password"));
        } else {
            console.log("New passwords do not match");
        }
    }

    function validateNewPassword(updatedPassword) {
        if (updatedPassword.newPassword === updatedPassword.confirmPassword) {
            return true
        }
        setError("confirmPassword", {
            type: "manual",
            message: "New Passwords do not match"
        });
    }

    return (
        <fieldset className="profile-content-border">
            <legend>Password</legend>
            <form className="form-profile-password" onSubmit={handleSubmit(onSubmit)}>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <label htmlFor="inputPassword" className="form-profile--label">Current Password</label>
                <input type="password" id="inputPassword"
                       ref={register('oldPassword', { required: true })}
                       autoComplete="current-password" className="form-control" />
                {errors?.oldPassword?.type === "required" && "Current Password is required"}
                <label htmlFor="inputNewPassword" className="form-label">New Password</label>
                <input type="password" id="inputNewPassword"
                       ref={register('newPassword',{ required: true })}
                       autoComplete="new-password" className="form-control" />
                {errors?.newPassword?.type === "required" && "New Password is required"}
                <label htmlFor="inputConfirmPassword" className="form-label">Confirm Password</label>
                <input type="password" id="inputConfirmPassword"
                       ref={register('confirmPassword', { required: true, validate: validateNewPassword })}
                       autoComplete="new-password" className="form-control" />
                {errors?.confirmPassword?.type === "required" && "Current Password is required"}
                {errors?.confirmPassword?.type === "manual" && `${errors.confirmPassword?.message}`}

                <div className="form-profile-buttons">
                    <button className="btn btn-s">Update</button>
                </div>
            </form>
        </fieldset>
    )
}

export default ProfilePassword;
