import "./PredictorDetailsResults.scss";

import {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import ResultRow from "../ResultRow/ResultRow";
import {PredictorService} from "../../service";

const PredictorDetailsResults = ({competition, ruleMap}) => {
    const [results, setResults] = useState([]);
    const [userResults, setUserResults] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const results = await PredictorService.getResults(competition?.competitionId);
            setResults(results);
        }
        if (competition) {
            fetchData();
        }
    }, [competition]);

    useEffect(() => {
        function compare(resultA, resultB) {
            if (resultA.points < resultB.points) return 1;
            if (resultA.points > resultB.points) return -1;
            return 0;
        }

        async function update() {
            results.sort(compare);
            setUserResults(results);
        }
        if (results) {
            update();
        }
    }, [results])

    return (
      <div>
          <table className="competition-table">
              <thead className="competition-table-head">
              <tr>
                  <td/>
                  <td>Name</td>
                  {userResults.length > 0 && Object.keys(userResults[0]?.rules).map((rule, index) =>
                    <td key={index} data-tip data-for={rule}>
                        {ruleMap[rule].display}
                        <ReactTooltip id={rule} place="top" effect="solid">{ruleMap[rule].tooltip}</ReactTooltip>
                    </td>
                  )}
                  <td data-tip data-for="points">
                      Points
                      <ReactTooltip id="points" place="top" effect="solid">Total Points</ReactTooltip>
                  </td>
              </tr>
              </thead>
              <tbody className="competition-table-body">
              {userResults?.map(result =>
                <ResultRow key={result.userId} result={result} />
              )}
              </tbody>
          </table>
      </div>
    )
}

export default PredictorDetailsResults;
