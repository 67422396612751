import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAltH, faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../service";

const ResultRow = ({result}) => {

  const [profile, setProfile] = useState();

  useEffect(() => {
    async function fetchProfile() {
      const profile = await UserService.getUserProfile(result.userId);
      setProfile(profile);
    }
    fetchProfile()
  }, [result]);

  return (
    <tr key={result?.profile?.userId}>
      {result.movement === 'UP' &&
      <td className="movement-up">
        <FontAwesomeIcon icon={faCaretUp} />
      </td>
      }
      {result.movement === 'DOWN' &&
      <td className="movement-down">
        <FontAwesomeIcon icon={faCaretDown} />
      </td>
      }
      {result.movement === 'NONE' &&
      <td className="movement-none">
        <FontAwesomeIcon icon={faArrowsAltH} />
      </td>
      }
      <td>{profile?.firstName} {profile?.lastName}</td>
      {Object.keys(result.rules).map((rule, index) =>
        <td key={index}>{result.rules[rule]}</td>
      )}
      <td>{result?.points}</td>
    </tr>
  )
}

export default ResultRow;
