import "./Menu.scss"
import {useContext, useEffect, useState} from "react";
import {slide as SlideMenu} from 'react-burger-menu'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChartLine, faEject, faMeteor, faNewspaper, faUser} from '@fortawesome/free-solid-svg-icons'
import {AuthService} from "../../service";
import {authStore} from "../../store";
import {store} from "../../store/Store";

const Menu = ({...props}) => {

  const {dispatch} = useContext(store)
  const [authState, setAuthState] = useState(authStore.initialState)
  const [isDark, setIsDark] = useState(localStorage.getItem("theme") === "dark")

  useEffect(()=> {
    authStore.subscribe(setAuthState);
    authStore.init();
    document.getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  },[]);

  function logout() {
    AuthService.logout();
    dispatch({type: "REMOVE_PROFILE", payload: null})
  }

  const toggleThemeChange = () => {
    if (isDark === false) {
      localStorage.setItem("theme", "dark");
      document.getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(true);
    } else {
      localStorage.setItem("theme", "light");
      document.getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(false);
    }
  }

  return (
    <SlideMenu {...props}>
      <div>
        <label className="form-label">Dark</label>
        <label className="switch">
          <input type="checkbox" className="form-competition-medium-input"
                 onChange={() => toggleThemeChange()} defaultChecked={isDark}/>
          <span className="slider round"/>
        </label>
      </div>
      <a href="/">
        <FontAwesomeIcon icon={faBars}/>
        <span className="menu-item">Fixtures</span>
      </a>
      <a href="/news">
        <FontAwesomeIcon icon={faNewspaper}/>
        <span className="menu-item">News</span>
      </a>
      {authState?.auth && (
        <a href="/competition">
          <FontAwesomeIcon icon={faMeteor}/>
          <span className="menu-item">Competitions</span>
        </a>
      )}
      {authState?.auth && (
        <a href="/create">
          <FontAwesomeIcon icon={faChartLine}/>
          <span className="menu-item">Create</span>
        </a>
      )}
      {authState?.auth && (
        <a href="/profile">
          <FontAwesomeIcon icon={faUser}/>
          <span className="menu-item">Profile</span>
        </a>
      )}
      {authState?.auth && (AuthService.isAdmin() || AuthService.isImpersonating()) && (
        <a href="/admin">
          <FontAwesomeIcon icon={faEject}/>
          <span className="menu-item">Admin</span>
        </a>
      )}
      {!authState?.auth && (
        <a href="/login">
          <FontAwesomeIcon icon={faUser}/>
          <span className="menu-item">Login</span>
        </a>
      )}
      {authState?.auth && (
        <button type="button" onClick={logout}>
          <FontAwesomeIcon icon={faEject}/>
          <span className="menu-item">Logout</span>
        </button>
      )}
    </SlideMenu>
  );
}

export default Menu;
