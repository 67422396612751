export const NewsService = {
    getRssFeed,
};

const CORS_PROXY = 'https://api.rss2json.com/v1/api.json?rss_url=';

const requestOptions: Object = {
    observe: 'body',
    referrerPolicy: 'origin-when-cross-origin',
    responseType: 'text'
};

function getRssFeed(url: string): any {
    return fetch(CORS_PROXY + url, requestOptions)
        .then(handleResponse)
        .then(feed => {
            return feed;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
