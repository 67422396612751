import {useContext, useEffect, useState} from "react";
import ScorePredictorFixture from "../ScorePredictorFixture/ScorePredictorFixture";
import {FixtureService, PredictorService} from "../../service";
import {store} from "../../store/Store";

const ScorePredictorSelection = ({userId, competitionId, teamList}) => {
  const {state, dispatch} = useContext(store);
  const [fixtures, setFixtures] = useState([]);
  const [activeGameweek, setActiveGameweek] = useState(0);
  const [gameweekSelections, setGameweekSelections] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!state.gameweek) {
        const currentGameweek = await FixtureService.getCurrentGameweek();
        dispatch({type: "SET_GAMEWEEK", payload: currentGameweek});
      }
      setActiveGameweek(state.gameweek);
    }

    if (!!competitionId) {
      fetchData();
    }
  }, [competitionId, dispatch, state.gameweek]);

  useEffect(() => {
    async function getGameweekSelections() {
      const selections = await PredictorService.getGameweekScoreSelection(competitionId, activeGameweek);
      setGameweekSelections(selections);
    }
    if (!!activeGameweek) {
      FixtureService.getGameweekFixtures(activeGameweek)
        .then(gameweekFixtures => setFixtures(gameweekFixtures))
        .catch(() => console.error('Failed to get gameweek fixtures'));
    }
    if (!!competitionId && !!activeGameweek) {
      getGameweekSelections();
    }
  }, [competitionId, activeGameweek]);

  function changeGameweek(by: number) {
    const newActiveGameweek = parseInt(activeGameweek) + by
    setActiveGameweek(newActiveGameweek)
  }

  return (
    <div className="competition-detail_match-details">
      {!!activeGameweek && (
        <div>
          <div className="gameweek_change_buttons">
            <button type="button" className="btn-link-item previous round" onClick={() => changeGameweek(-1)}>
              <span className="previous_element">&#8249;</span>
            </button>
            <button type="button" className="btn-link-item next round" onClick={() => changeGameweek(1)}>
              <span className="next_element">&#8250;</span>
            </button>
          </div>
          <div className="competition-detail_title">
            Gameweek {activeGameweek}
          </div>
        </div>
      )}
      {!!fixtures &&
      <div className="competition-detail_match">
        <ul className="gameweek_list">
          {!!teamList?.length && !!activeGameweek && !!gameweekSelections && fixtures.map(fixture => (
            <ScorePredictorFixture
              key={fixture.id}
              activeGameweek={activeGameweek}
              userId={userId}
              competitionId={competitionId}
              fixture={fixture}
              gameweekSelections={gameweekSelections}
              teamList={teamList}
            />
          ))}
        </ul>
      </div>
      }
    </div>
  )
}

export default ScorePredictorSelection
