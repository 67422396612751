import Fixtures from "./Fixture/Fixtures";

const Home = () => {
  return (
    <div className="content_main">
      <Fixtures />
    </div>
  )
}

export default Home;
