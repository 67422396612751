import './CompetitionList.scss';

import {FixtureService, PredictorService} from "../../service";
import {useContext, useEffect, useState} from "react";
import CompetitionListItem from "./CompetitionListItem";
import {store} from '../../store/Store'

const CompetitionList = () => {

    const {dispatch} = useContext(store);
    const [competitions, setCompetitions] = useState([]);
    const [code, setCode] = useState("");

    useEffect(() => {
        async function fetchData() {
            const competitions = await PredictorService.getCompetitions();
            const currentGameweek = await FixtureService.getCurrentGameweek();
            setCompetitions(competitions);
            dispatch({type: "SET_GAMEWEEK", payload: currentGameweek});
        }
        fetchData();
    }, [dispatch]);

    const handleCodeInput = (evt) => {
        setCode(evt.target.value);
    };

    const joinCompetition = async () => {
        PredictorService.joinCompetition(code);
        const competitions = await PredictorService.getCompetitions();
        setCompetitions(competitions);
    };

    return (
        <div className="content_main">
            <div className="competition-list">
                <ul>
                    {competitions && competitions.map(competition => (
                        <CompetitionListItem key={competition.competitionId}
                                             competition={competition}/>
                    ))}
                </ul>
            </div>
            <div className="competition_join-competition">
                <div className="competition_join-label">
                    <span>Code:</span>
                </div>
                <div className="competition_join-input">
                    <input className="form-control ng-untouched ng-pristine ng-invalid"
                           id="inputCode"
                           name="competitionCode" required=""
                           type="text" onChange={handleCodeInput} />
                </div>
                <div className="competition_join-button">
                    <button className="btn btn-s" type="button" onClick={joinCompetition}>Join</button>
                </div>
            </div>
        </div>
    )
}

export default CompetitionList;
