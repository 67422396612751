import "./LmsStatus.scss";
import {useContext, useEffect, useState} from "react";
import StatusRow from "./StatusRow";
import {FixtureService, PredictorService} from "../../service";
import {Modal} from "../Modal/Modal";
import {store} from '../../store/Store';

const LmsStatus = ({competition, token, gameweek}) => {
    const {state, dispatch} = useContext(store);
    const [statuses, setStatuses] = useState();
    const [userStatuses, setUserStatuses] = useState();
    const [show, setShow] = useState(false);
    const [usersForRestart, setUsersForRestart] = useState([]);

    useEffect(() => {
        async function fetchData() {
            if (!state.gameweek) {
                const gameweek = await FixtureService.getCurrentGameweek();
                dispatch({type: "SET_GAMEWEEK", payload: gameweek})
            }
            const statuses =
              await PredictorService.getRoundStatus(competition?.competitionId, competition?.round, gameweek);
            setStatuses(statuses);
        }

        fetchData();
    }, [competition, gameweek, state.gameweek]);

    useEffect(() => {
        function compare(resultA, resultB) {
            if (resultA.lives < resultB.lives) return 1;
            if (resultA.lives > resultB.lives) return -1;
            return 0;
        }

        async function sort() {
            statuses.sort(compare);
            if (statuses.filter(st => st.eliminated === false).length === 0) {
                statuses[0].winner = true;
            }
            setUserStatuses(statuses);
        }
        if (!!statuses) {
            console.log('Sort statues', statuses);
            sort();
        }
    }, [statuses]);

    const showModal = () => {
        setShow(true);
    };

    const hideModal = () => {
        setShow(false);
    };

    const restartCompetition = () => {
        console.log("Restarting competition", usersForRestart);
        PredictorService.restartCompetition(competition.competitionId, usersForRestart).then(() => hideModal());
    }

    const toggleUser = async (evt) => {
        if (evt.target.checked) {
            setUsersForRestart(userIds => [...userIds, evt.target.value]);
        } else {
            setUsersForRestart(userIds => userIds.filter(id => id !== evt.target.value));
        }
    }

    return (
        <div>
            {token?.user_name === competition.owner && competition.ended &&
                <div className="restart-competitino">
                    <Modal show={show} handleClose={hideModal}>
                        {userStatuses?.map((status, index) =>
                            <div key={`restart-user-${index}`} className="user-list">
                                <input type="checkbox" value={status.userId} onChange={toggleUser}/>
                                <span>{status.userId}</span>
                            </div>
                        )}
                        <input type="button" className="btn btn-lg" value="Restart" onClick={restartCompetition}/>
                    </Modal>
                    <button className="btn btn-lg btn-primary btn-block" type="button" onClick={showModal}>Restart</button>
                </div>
            }
            {!!competition && (
                <table className="competition-table">
                    <thead className="competition-table-head">
                    <tr>
                        <td>User</td>
                        <td>Selection</td>
                        <td>Lives (out of {competition.lives})</td>
                    </tr>
                    </thead>
                    <tbody className="competition-table-body">
                    {userStatuses?.map((status, index) =>
                        <StatusRow key={`user-status-${index}`} competition={competition} status={status} />
                    )}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default LmsStatus
