import './Header.scss';
import ProfileImage from '../../assets/images/profile_placeholder.svg';
import {useContext, useEffect, useState} from "react";
import {store} from "../../store/Store";

const HeaderLogin = () => {
  const {state} = useContext(store);
  const [profilePic, setProfilePic] = useState();

  useEffect(() => {
    if (state?.profile?.profilePic) {
      setProfilePic(state.profile.profilePic);
    }
  }, [state?.profile])

  return (
    <div className="header_login">
      <div className="header_login-details">
        {!!profilePic && (
          <img alt="" className="header_profileImage" src={profilePic} />
        )}
        {!profilePic && (
          <img alt="" className="header_profileImage" src={ProfileImage} />
        )}
      </div>
    </div>
  )
}

export default HeaderLogin;
