import "./LmsSelection.scss";
import {useContext, useEffect, useState} from "react";
import LmsFixture from "../LmsFixture/LmsFixture";
import {FixtureService, PredictorService} from "../../service";
import {store} from '../../store/Store';

const LmsSelection = ({competitionId, round, eliminated}) => {
    const {state} = useContext(store);
    const [fixtures, setFixtures] = useState();
    const [started, setGameweekStarted] = useState(false);
    const [selections, setSelections] = useState();
    const [activeGameweek, setActiveGameweek] = useState();
    const [gameweekSelection, setGameweekSelection] = useState();

    useEffect(() => {
        async function fetchData() {
            const selections = await PredictorService.getMyLmsSelections(competitionId, round);
            setSelections(selections);
            if (!!state.gameweek) {
                setActiveGameweek(parseInt(state.gameweek));
            }
        }
        fetchData()
    }, [competitionId, round, state.gameweek]);

    useEffect(() => {
        async function fetchData() {
            const gameweekFixtures = await FixtureService.getGameweekFixtures(activeGameweek);
            setFixtures(gameweekFixtures);
            setGameweekStarted(gameweekFixtures?.filter(fixture => fixture.started).length > 0);
        }
        fetchData();
    }, [activeGameweek]);

    useEffect(() => {
        function fetchData() {
            const activeGameweekSelection = selections?.filter(selection => selection.gameweek === activeGameweek);
            setGameweekSelection(activeGameweekSelection[0]);
        }
        if (selections && activeGameweek) {
            fetchData();
        }
    }, [selections, activeGameweek]);

    function toggleSelection(teamId: number, matchId: number) {
        if (!started && !eliminated) {
            if (gameweekSelection !== undefined) {
                if (gameweekSelection.lmsSelectionId !== undefined
                    && teamId === gameweekSelection.teamId && matchId === gameweekSelection.matchId) {
                    PredictorService.removeLmsSelection(competitionId, state.gameweek, gameweekSelection.lmsSelectionId)
                        .then(() => setGameweekSelection())
                        .catch(() => console.error('Failed to remove selection'));
                } else {
                    gameweekSelection.teamId = teamId;
                    gameweekSelection.matchId = matchId;
                    PredictorService.updateSelection(gameweekSelection)
                        .then(response => setGameweekSelection(response))
                        .catch(() => console.error('Failed to update selection'));
                }
            } else {
                const selection = {
                    'competitionId': competitionId,
                    'gameweek': state.gameweek,
                    'lmsSelectionId': null,
                    'matchId': matchId,
                    'round': round,
                    'teamId': teamId,
                }
                PredictorService.updateSelection(selection)
                    .then(response => setGameweekSelection(response))
                    .catch(() => console.error('Failed to update selection'));
            }
        } else {
            console.log("The gameweek is started or you have been eliminated");
        }
    }

    function changeGameweek(by: number) {
        const newActiveGameweek = activeGameweek + by;
        setActiveGameweek(newActiveGameweek);
    }

    return (
        <div className="competition-detail_match-details">
            {!!activeGameweek && (
                <div>
                    <div className="gameweek_change_buttons">
                        <button type="button" className="btn-link-item previous round"
                                onClick={() => changeGameweek(-1)}>
                            <span className="previous_element">&#8249;</span>
                        </button>
                        <button type="button" className="btn-link-item next round"
                                onClick={() => changeGameweek(1)}>
                            <span className="next_element">&#8250;</span>
                        </button>
                    </div>
                    <div className="competition-detail_title">
                        Gameweek {activeGameweek}
                    </div>
                </div>
            )}
            {!!fixtures &&
            <div className="competition-detail_match">
                <ul className="gameweek_list">
                    {!!selections && fixtures && fixtures.map(fixture => (
                        <LmsFixture key={fixture.id} fixture={fixture}
                                    activeGameweek={activeGameweek}
                                    selections={selections} gameweekSelection={gameweekSelection}
                                    started={started}
                                    toggleSelection={toggleSelection}
                                    eliminated={eliminated}/>
                    ))}
                </ul>
            </div>
            }
        </div>
    )
}

export default LmsSelection
