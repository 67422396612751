import {useContext, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faTimesCircle, faTrashAlt, faTrophy} from "@fortawesome/free-solid-svg-icons";
import {AuthService, UserService} from "../../service";
import {store} from "../../store/Store";

const StatusRow = ({status, competition}) => {
    const {state} = useContext(store);
    const [token, setToken] = useState();
    const [profile, setProfile] = useState();
    const [gameweekSelection, setGameweekSelection] = useState();
    let teamClass = useRef("badge badge-away");

    useEffect(() => {
        async function setAuthToken() {
            const auth = await AuthService.getAuth();
            setToken(auth);
        }
        setAuthToken();
    }, [])

    useEffect(() => {
        function getTeamName(teamId: number) {
            return state.teams.filter(team => team.id === teamId)[0].name;
        }

        async function fetchProfile() {
            const profile = await UserService.getUserProfile(status.userId);
            setProfile(profile);
            if (!!status.selection.teamId && !!state.teams.length) {
                const teamName = getTeamName(status.selection.teamId);
                teamClass.current = "badge badge-away " + teamName;
                status.selection.teamName = teamName;
                setGameweekSelection(status.selection);
            }
        }
        if (!!status) {
            fetchProfile();
        }
    }, [status]);

    function removeUser() {
        console.log('Remove user ', status.userId);
    }

    return (
        <tr key={status.userId}>
            <td>
                {profile?.firstName} {profile?.lastName}
                {competition.owner === token?.user_name &&
                <FontAwesomeIcon icon={faTrashAlt} className="right" onClick={removeUser}/>
                }
            </td>
            {status?.eliminated && !status?.winner && (
                <td className="error lms-status">
                    <span className="lms-status-status">Eliminated</span>
                    <span><FontAwesomeIcon icon={faTimesCircle} className="status-badge" size="1x"/></span>
                </td>
            )}
            {status?.winner && (
              <td className="winner lms-status">
                  <span className="lms-status-status">Winner</span>
                  <span><FontAwesomeIcon icon={faTrophy} className="status-badge" size="1x"/></span>
              </td>
            )}
            {!status?.eliminated && gameweekSelection && (
                 <td className="lms-status">
                     <span className="gameweek_match_teamname lms-status-status">{gameweekSelection.teamName}</span>
                     <span className={teamClass.current} />
                 </td>
            )}
            {!status?.eliminated && !gameweekSelection && (
                <td className="warning lms-status">
                    <span className="lms-status-status">No Selection</span>
                    <span><FontAwesomeIcon icon={faExclamationCircle} className="status-badge" size="2x"/></span>
                </td>
            )}
            <td>{status.lives}</td>
        </tr>
    )
}

export default StatusRow;
