import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import {store} from "../../store/Store";

const LmsFixture = ({fixture, activeGameweek, selections, gameweekSelection, toggleSelection, eliminated}) => {
    const {state} = useContext(store);
    const [fixtureId, setFixtureId] = useState();
    const [homeTeamSelectionColor, setHomeTeamSelectionColor] = useState('');
    const [awayTeamSelectionColor, setAwayTeamSelectionColor] = useState('');
    const [homeTeamName, setHomeTeamName] = useState('');
    const [awayTeamName, setAwayTeamName] = useState('');
    const [previouslySelectedHomeTeam, setPreviouslySelectedHomeTeam] = useState(true);
    const [previouslySelectedAwayTeam, setPreviouslySelectedAwayTeam] = useState(true);
    const [scoreSeparator, setScoreSeparator] = useState(' v');
    const [homeTeamClass, setHomeTeamClass] = useState('badge badge-home');
    const [awayTeamClass, setAwayTeamClass] = useState('badge badge-away');
    const futureGameweek = activeGameweek > state.gameweek;

    useEffect(() => {
        function selectionColor(teamId: number): string {
            return isCurrentTeamSelection(teamId) ? 'green' : '';
        }

        function isCurrentTeamSelection(teamId: number) {
            return teamId === gameweekSelection?.teamId && fixture.id === gameweekSelection?.matchId;
        }

        function isPreviousSelection(teamId: number) {
            return selections !== undefined
              && selections.length > 0
              && selections.filter(selection => selection.teamId === teamId && selection.gameweek !== activeGameweek).length > 0;
        }

        function isNotPreviousSelectedTeam(teamId: number) {
            return !isPreviousSelection(teamId) && (!fixture.started || isCurrentTeamSelection(teamId, fixture.id)) && !futureGameweek;
        }

        function setSelectionDetails(fixture) {
            setPreviouslySelectedHomeTeam(!isNotPreviousSelectedTeam(fixture.teamH));
            setPreviouslySelectedAwayTeam(!isNotPreviousSelectedTeam(fixture.teamA));
            setHomeTeamSelectionColor(selectionColor(fixture.teamH));
            setAwayTeamSelectionColor(selectionColor(fixture.teamA));
        }

        if (!!fixture) {
            setScoreSeparator((fixture.finished || fixture.finishedProvisional) ? `${fixture?.teamHScore} - ${fixture?.teamAScore}` : ' v');
            setFixtureId(fixture.id);
            setSelectionDetails(fixture);
        }
    }, [fixture, gameweekSelection, activeGameweek, futureGameweek, selections]);

    useEffect(() => {
        if (!!fixture && !!state.teams.length) {
            const teamList = state.teams;
            const homeTeam = teamList.filter(team => team.id === fixture.teamH)[0].name;
            const awayTeam = teamList.filter(team => team.id === fixture.teamA)[0].name;
            setHomeTeamName(homeTeam);
            setAwayTeamName(awayTeam);
            setHomeTeamClass(`badge badge-home ${homeTeam}`);
            setAwayTeamClass(`badge badge-away ${awayTeam}`);
        }
    }, [fixture, state.teams]);

    return (
        <li key={fixture.id}>
            <div className="gameweek_match">
                <div className="gameweek_match_hometeam">
                    {!previouslySelectedHomeTeam ?
                      <FontAwesomeIcon icon={faCheck} className="home-team-selection" color={homeTeamSelectionColor}
                                       onClick={() => toggleSelection(fixture.teamH, fixtureId)}/>
                      : <div className="home-team-selection empty-lms-selection-placeholder"/>
                    }
                    <span className="gameweek_match_teamname">{homeTeamName}</span>
                    <span className={homeTeamClass} />
                </div>
                <div className="gameweek_match_status">
                    <span className="match-status_score-active" >{scoreSeparator}</span>
                </div>
                <div className="gameweek_match_awayteam">
                    <span className={awayTeamClass} />
                    <span className="gameweek_match_teamname">{awayTeamName}</span>
                    {!previouslySelectedAwayTeam &&
                    <FontAwesomeIcon icon={faCheck} className="away-team-selection" color={awayTeamSelectionColor}
                                     onClick={() => toggleSelection(fixture.teamA, fixtureId)}/>
                    }
                    {(eliminated || previouslySelectedAwayTeam) &&
                    <div className="away-team-selection empty-lms-selection-placeholder" />
                    }
                </div>
            </div>
        </li>
    )
}

export default LmsFixture
