import {useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import {TabContent, TabLink, Tabs} from "react-tabs-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import ScorePredictorSelection from "../ScorePredictorSelection/ScorePredictorSelection";
import {AuthService, FixtureService, PredictorService} from "../../service";
import PredictorDetailsResults from "../PredictorDetailsResults/PredictorDetailsResults";
import PredictorRules from "../PredictorRules/PredictorRules";

const types = ['result', 'team_score', 'goal_diff']
const selections = ['score']
const ruleMap = {
  'result': {display: 'Res', tooltip: 'Results'},
  'team_score': {display: 'TS', tooltip: 'Team Scores'},
  'goal_diff': {display: 'GD', tooltip: 'Goal Difference'},
}

const ScorePredictorDetails = () => {
  const [competition, setCompetition] = useState({});
  const [competitionCode, setCompetitionCode] = useState();
  const [token, setToken] = useState();
  const [teams, setTeams] = useState([]);
  const location = useLocation();
  const [rules, setRules] = useState();

  let competitionId = useRef(0);

  useEffect(() => {
    async function setAuth() {
      const auth = await AuthService.getAuth();
      setToken(auth);
    }
    async function getTeams() {
      const teamList = await FixtureService.getTeams();
      setTeams(teamList);
    }
    setAuth();
    getTeams();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    competitionId.current = parseInt(searchParams.get('id'));
    async function fetchData() {
      const competition = await PredictorService.getCompetitionDetails(competitionId.current);
      const rules = await PredictorService.getRules(competitionId.current);
      setCompetition(competition);
      setCompetitionCode(competition?.code);
      setRules(rules);
    }
    fetchData();
  }, [location]);

  const updateRule = (index, rule) => {
    rules[index] = rule;
    PredictorService
      .updateRules(competitionId.current, rules)
      .catch(() => console.error('Failed to update rules'))
  }

  async function regenerateCode() {
    const newCode = await PredictorService.regenerateCode(competition.competitionId);
    setCompetitionCode(newCode);
  }

  return (
    <div className="content_main">
      <fieldset className="form-competition-content-border">
        <legend>{competition?.name}</legend>
        <section className="competition_group">
          {token?.user_name === competition?.owner && (
            <h4 className="competition_group_title">
              <div className="image competition_group_flag image-loading">
                <div className="competition_match_teamname">
                  (Code: {competitionCode})
                  <FontAwesomeIcon className="redo-icon" icon={faRedo} onClick={regenerateCode} />
                </div>
              </div>
            </h4>
          )}
          <Tabs className="competition-tabset">
            <ul className="nav nav-tabs justified-content-start">
              <li className="nav-item">
                <TabLink to="selection">Selection</TabLink>
              </li>
              <li className="nav-item">
                <TabLink to="table">Table</TabLink>
              </li>
              {token?.user_name === competition?.owner && (
                <li className="nav-item">
                  <TabLink to="rules">Rules</TabLink>
                </li>
              )}
            </ul>

            <TabContent for="selection">
              {!!teams?.length && competition && (
                <ScorePredictorSelection
                  userId={token?.user_name}
                  competitionId={competitionId.current}
                  round={competition.round}
                  teamList={teams}
                />
              )}
            </TabContent>

            <TabContent for="table">
              {competition && (
                <PredictorDetailsResults
                  competition={competition}
                  ruleMap={ruleMap}
                />
              )}
            </TabContent>

            <TabContent for="rules">
              {rules &&
                <PredictorRules
                  types={types}
                  selections={selections}
                  isNew={false}
                  rules={rules}
                  updateRule={updateRule}
                  minRules={2}
                />
              }
            </TabContent>
          </Tabs>
        </section>
      </fieldset>
    </div>
  )
}

export default ScorePredictorDetails
