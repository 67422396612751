import "./PredictorRules.scss";

const PredictorRules = ({types, selections, isNew, rules, ruleSet, removeRule, updateRule, minRules=1}) => {

    function onUpdate(evt, index) {
        evt.preventDefault();
        rules[index][evt.target.name] = evt.target.value;
        updateRule(index, rules[index]);
    }

    return (
        <div className="form-competition-update-rules">
            {rules.map((rule, index) =>
                <fieldset key={index} className="form-competition-content-border">
                    <legend>Rule</legend>
                    {rules.length > minRules &&
                        <span className="rule-close" onClick={() => removeRule(index)}>X</span>
                    }
                    <div className="form-competition-rule">
                        {selections.length > 1 &&
                        <div className="form-competition-section-medium">
                            <label className="form-label">Selection</label>
                            <select className="form-control rule-select-box" name="selection"
                                    onChange={evt => onUpdate(evt, index)}
                                    defaultValue={rule?.selection}>
                                {selections.map(selection => (
                                  <option key={selection} value={selection}>{selection}</option>
                                ))}
                            </select>
                        </div>
                        }
                        <div className="form-competition-section-medium">
                            <label className="form-label">Type</label>
                            <select className="form-control rule-select-box" name="type" onChange={evt => onUpdate(evt, index)}
                                    defaultValue={rule?.type}>
                                {types.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-competition-section-medium">
                            <label className="form-label">Points</label>
                            <input className="form-competition-rule-points-input" type="number" min="1" max="20" name="points" onChange={onUpdate}
                                   defaultValue={rule?.points}/>
                        </div>
                    </div>
                </fieldset>
            )}
            {!isNew && (
                <button className="btn btn-lg btn-primary btn-block form-button rules-button" type="button" >Update</button>
            )}
        </div>
    )
}

export default PredictorRules;
