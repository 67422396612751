export const FixtureService = {
    getCurrentGameweek,
    getFixtures,
    getGameweekFixtures,
    getSuggestions,
    getLeagues,
    getLeagueTeams,
    getMatchDetails,
    getTeams,
    getTeamFixtures,
    getTeamInfo,
}

const FIXTURE_SERVICE_URL: string = process.env.REACT_APP_FIXTURE_SERVICE_URL;

const headers = {
    'Content-Type':  'application/json',
    'X-Requested-With': 'XMLHttpRequest',
}

function getCurrentGameweek() {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${FIXTURE_SERVICE_URL}/fixtures/gameweek/current`, requestOptions)
        .then(handleResponse)
        .then(teams => {
            return teams;
        }).catch(() => {

        });
}

function getFixtures(date: string): any {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (date) {
        return fetch(`${FIXTURE_SERVICE_URL}/fixtures/${date}`, requestOptions)
            .then(handleResponse)
            .then(fixtures => {
                return fixtures.leagues;
            });
    }
}

function getGameweekFixtures(gameweekId: number): any {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (gameweekId) {
        return fetch(`${FIXTURE_SERVICE_URL}/fixtures/gameweek/${gameweekId}`, requestOptions)
            .then(handleResponse)
            .then(fixtures => {
                return fixtures;
            });
    }
}

function getSuggestions(gameweek: number) {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (gameweek) {
        return fetch(`${FIXTURE_SERVICE_URL}/fixtures/gameweek/${gameweek}/suggested`, requestOptions)
            .then(handleResponse)
            .then(teams => {
                return teams;
            }).catch(() => {

            });
    }
}

function getLeagues(countryCode: string): any {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    if (countryCode) {
        return fetch(`${FIXTURE_SERVICE_URL}/country/${countryCode}/leagues`, requestOptions)
            .then(handleResponse)
            .then(leagues => {
                return leagues;
            });
    }
}

function getLeagueTeams(countryCode: string, leagueId: number) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    if (countryCode && leagueId) {
        return fetch(`${FIXTURE_SERVICE_URL}/country/${countryCode}/leagues/${leagueId}/teams`, requestOptions)
            .then(handleResponse)
            .then(teams => {
                return teams;
            });
    }
}

function getMatchDetails(matchId: number): any {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (matchId) {
        return fetch(`${FIXTURE_SERVICE_URL}/match/${matchId}`, requestOptions)
            .then(handleResponse)
            .then(matchDetails => matchDetails);
    }
}

function getTeams() {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${FIXTURE_SERVICE_URL}/team/pl`, requestOptions)
        .then(handleResponse)
        .then(teams => {
            return teams;
        }).catch(() => {

        });
}

function getTeamFixtures(teamId) {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (teamId) {
        return fetch(`${FIXTURE_SERVICE_URL}/team/${teamId}/fixtures`, requestOptions)
            .then(handleResponse)
            .then(fixtures => {
                return fixtures;
            });
    }
}

function getTeamInfo(teamId) {
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    if (teamId) {
        return fetch(`${FIXTURE_SERVICE_URL}/team/${teamId}`, requestOptions)
            .then(handleResponse)
            .then(teamInfo => {
                return teamInfo;
            });
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
