import {Suspense, useEffect, useState} from "react";

const ScorePredictorUserSelection = ({otherUsersSelection, homeTeamName, awayTeamName, fixture}) => {
  const [collapsableSpan, setCollapsableSpan] = useState("collapsable collapsable-closed");
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const collapsableSpanClass = showComponent ? 'collapsable-opened' : 'collapsable-closed'
    setCollapsableSpan(`collapsable ${collapsableSpanClass}`);
  }, [showComponent]);

  return (
    <>
      {fixture.started &&
        <div className="gameweek_match_users-selected-details-score">
          ({homeTeamName} {fixture.teamHScore}  - {fixture.teamAScore} {awayTeamName})
        </div>
      }
      {otherUsersSelection?.length === 1 &&
      <div className="gameweek_match_users-selected">
        <div className="gameweek_match_users-selected-title" onClick={() => setShowComponent(!showComponent)}>
          <span className="gameweek_match_users-selected-details">{otherUsersSelection?.length} other user has selected</span>
          <span className={collapsableSpan}/>
        </div>
        {showComponent && (
          <Suspense fallback={<div>Fetching User Selections...</div>}>
            {otherUsersSelection?.map((score) =>
            fixture.started
              ? <div key={fixture.id + "_" + score.userId} className="gameweek_match_users-selected-details-users">
                [{score.userId}] {homeTeamName} {score.homeScore} - {score.awayScore} {awayTeamName}
              </div>
              : <div key={fixture.id + "_" + score.userId} className="gameweek_match_users-selected-details-users">
                {score.userId}
              </div>
          )}
          </Suspense>
        )}
      </div>
      }
      {otherUsersSelection?.length !== 1 &&
      <div className="gameweek_match_users-selected">
        <div className="gameweek_match_users-selected-title" onClick={() => setShowComponent(!showComponent)}>
          <span className="gameweek_match_users-selected-details">{otherUsersSelection?.length} other users have selected</span>
          <span className={collapsableSpan} />
        </div>
        {showComponent && (
          <Suspense fallback={<div>Fetching User Selections...</div>}>
          {otherUsersSelection?.map((score) =>
            fixture.started
              ? <div key={fixture.id + "_" + score.userId} className="gameweek_match_users-selected-details-users">
                [{score.userId}] {homeTeamName} {score.homeScore} - {score.awayScore} {awayTeamName}
              </div>
              : <div key={fixture.id + "_" + score.userId} className="gameweek_match_users-selected-details-users">
                {score.userId}
              </div>
          )}
          </Suspense>
        )}
      </div>
      }
    </>
  )
}

export default ScorePredictorUserSelection;
