import {AuthService} from './AuthService'

export const UserService = {
    getUserProfile,
    getUserProfilePic,
    updateUserProfile,
    uploadProfilePic,
}

const AUTH_SERVICE_URL: string = process.env.REACT_APP_AUTH_SERVICE_URL;
const USER_SERVICE_URL: string = process.env.REACT_APP_USER_SERVICE_URL;

function getHeaders() {
    return {
        'Authorization': `Bearer ${AuthService.getAccessToken()}`,
        'Content-Type':  'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
}

function getUserProfile(userId: string) {
    const authString = localStorage.getItem('auth');
    if (authString) {
        const requestOptions = {
            method: 'GET',
            headers: getHeaders(),
        };
        return fetch(`${AUTH_SERVICE_URL}/account/${userId}`, requestOptions)
            .then(handleResponse)
            .then(users => {
                return users;
            });
    }
}

function getUserProfilePic(userId: string) {
    const authString = localStorage.getItem('auth');
    if (authString) {
        const requestOptions = {
            method: 'GET',
            headers: getHeaders(),
        };
        return fetch(`${USER_SERVICE_URL}/profile/${userId}`, requestOptions)
          .then(handleResponse)
          .then(profile => {
              return profile.profilePic;
          });
    }
}

function updateUserProfile(userId: string, profile: any) {
    if (userId && profile) {
        const requestOptions = {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(profile),
        };
        return fetch(`${USER_SERVICE_URL}/profile/${userId}`, requestOptions)
            .then(handleResponse)
            .catch(error => console.error(error));
    }
}

function uploadProfilePic(userId: string, file: File) {
    const auth = localStorage.getItem('auth');
    if (auth) {
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${AuthService.getAccessToken()}`,
            },
            body: formData,
        };
        return fetch(`${USER_SERVICE_URL}/profile/${userId}/picture`, requestOptions)
            .then(handleResponse);
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
