import './Modal.scss';

export const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <div className="modal-header">
                    <h4 className="modal-title">Select Users</h4>
                    <input type="button" className="modal-close" value="X" onClick={handleClose}/>
                </div>
                <div className="modal-body">
                    <div>{children}</div>
                </div>
            </section>

        </div>
    );
};
