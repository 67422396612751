import "./PredictorDetailsSelection.scss";

import {useContext, useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import Player from "../Player/Player";
import DoublePoints from "../DoublePoints/DoublePoints";
import LineupOnlyToggle from "../LineupOnlyToggle/LineupOnlyToggle";
import {AuthService, FixtureService, PredictorService} from "../../service";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {store} from "../../store/Store";

const PredictorDetailsSelection = ({competition, teamInfo, rules}) => {
  const {state, dispatch} = useContext(store);
  const [token, setToken] = useState();
  const [fixtureList, setFixtureList] = useState([]);
  const [match, setMatch] = useState([]);
  const [matchDetails, setMatchDetails] = useState(null);
  const [selections, setSelections] = useState(null);
  const [players, setGroupedPlayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onlyLineup, setOnlyLineup] = useState(false);
  const location = useLocation();

  let competitionId = useRef(0);
  let awayTeamLogo = useRef();
  let awayTeamName = useRef();
  let homeTeamLogo = useRef();
  let homeTeamName = useRef();
  let activeMatchIndex = useRef(0);
  let matchIndex = useRef(0);

  useEffect(() => {
    async function getAuth() {
      const auth = await AuthService.getAuth();
      setToken(auth);
    }
    getAuth();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    competitionId.current = searchParams.get('id');
  }, [location]);

  useEffect(() => {
    if (!!teamInfo) {
      dispatch({type:"TEAM_FIXTURES", payload: teamInfo.fixtures})
      setFixtureList(teamInfo.fixtures.allFixtures.fixtureList)
    }
  }, [teamInfo]);

  useEffect(() => {
    if (!!fixtureList.length) {
      const firstActiveIndex = fixtureList.findIndex(fixture => fixture.status.finished === false);
      activeMatchIndex.current = firstActiveIndex;
      matchIndex.current = firstActiveIndex;
      const currentMatch = fixtureList[matchIndex.current];
      setMatch(currentMatch);
    }
  }, [fixtureList]);

  useEffect(() => {
    async function update() {
      const matchSelections = await PredictorService.getSelections(competitionId.current, match.id);
      const matchDetails = await FixtureService.getMatchDetails(match.id);
      setSelections(matchSelections);
      setMatchDetails(matchDetails)
    }
    if (!!match) {
      update();
    }
  }, [match]);

  useEffect(() => {
    if (!!match && !!teamInfo) {
      if (match.homeFixture) {
        awayTeamLogo.current = `https://images.fotmob.com/image_resources/logo/teamlogo/${match.opponent?.id}_xsmall.png`;
        awayTeamName.current = match.opponent?.name;
        homeTeamLogo.current = `https://images.fotmob.com/image_resources/logo/teamlogo/${teamInfo.details?.id}_xsmall.png`;
        homeTeamName.current = teamInfo.details?.name;
      } else {
        awayTeamLogo.current = `https://images.fotmob.com/image_resources/logo/teamlogo/${teamInfo.details?.id}_xsmall.png`;
        awayTeamName.current = teamInfo.details?.name;
        homeTeamLogo.current = `https://images.fotmob.com/image_resources/logo/teamlogo/${match.opponent?.id}_xsmall.png`;
        homeTeamName.current = match.opponent?.name;
      }
    }
  }, [match, teamInfo]);

  useEffect(() => {
    let lineupPlayers = [];
    if (!!match && !!matchDetails) {
      const lineup = matchDetails?.content?.lineup.lineup;
      const teamLineup = lineup.filter(lineup => lineup?.teamId === teamInfo.details.id)[0];
      if (!!teamLineup?.bench) {
        lineupPlayers = teamLineup.bench?.concat(teamLineup.players[0], teamLineup.players[1],
          teamLineup.players[2], teamLineup.players[3]);
      }
    }
    if (!!teamInfo) {
      const updatedPlayers = updatePlayersWithLineup(teamInfo.players, lineupPlayers);
      setGroupedPlayers(updatedPlayers);
    }
  }, [match, teamInfo, matchDetails]);

  function updatePlayersWithLineup(players, lineup) {
    let updatedPlayers = JSON.parse(JSON.stringify(players));
    Object.entries(updatedPlayers).forEach(([position, posPlayers]) => {
      updatedPlayers[position] = posPlayers.map(p => {
        const lineupPlayer = lineup.filter(l => Number(p.id) === Number(l.id))[0];
        if (!!lineupPlayer) {
          return {...p, lineupClass: lineupPlayer.position === 'Substitute' ? 'substitute' : 'starting-lineup' }
        }
        return p;
      });
    });
    return updatedPlayers;
  }

  function previousMatch() {
    matchIndex.current--;
    const match = fixtureList[matchIndex.current];
    setMatch(match);
    setOnlyLineup(false);
  }

  function nextMatch() {
    matchIndex.current++;
    const match = fixtureList[matchIndex.current];
    setMatch(match);
    setOnlyLineup(false);
  }

  function deleteSelection(deletedSelection) {
    const index = selections.findIndex(selection => selection.competitionSelectionId === deletedSelection.competitionSelectionId);
    const currentSelections = [...selections];
    currentSelections.splice(index, 1);
    setSelections(currentSelections);
  }

  function updateSelection(updatedSelection) {
    const index = selections.findIndex(selection => selection.competitionSelectionId === updatedSelection.competitionSelectionId);
    const currentSelections = [...selections];
    currentSelections[index] = updatedSelection;
    setSelections(currentSelections);
  }

  async function toggleSelection(selectedBy, matchId, rule, player) {
    if (match?.status.started) {
      setErrorMessage("Match already started");
      setTimeout(() => setErrorMessage(null),5000);
    } else {
      const userSelection = selectedBy.filter(selection => selection.userId === token?.user_name);
      const selectionForRule = selections.filter(selection => selection.userId === token?.user_name && selection.ruleId === rule.ruleId);
      const selection = {
        playerId: player.id,
        playerName: player.name,
        userId: token?.user_name,
        ruleId: rule.ruleId,
        teamId: teamInfo.details.id,
      }
      if (selectionForRule.length > 0) {
        // If it's the current player
        if (userSelection.length > 0) {
          try {
            await PredictorService.removeSelection(competitionId.current, userSelection[0].competitionSelectionId);
            deleteSelection(selection);
          } catch (e) {
            console.error(e);
          }
        } else {
          try {
            selection.competitionSelectionId = selectionForRule[0].competitionSelectionId;
            await PredictorService.toggleSelection(competitionId.current, matchId, selection);
            updateSelection(selection);
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        try {
          await PredictorService.addSelection(competitionId.current, matchId, selection);
          const currentSelections = [...selections];
          currentSelections.push(selection);
          setSelections(currentSelections);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  function toggleLineupOnly() {
    setOnlyLineup(!onlyLineup);
  }

  return (
    <>
      {errorMessage && (
        <ErrorMessage message={errorMessage} />
      )}
      <div className="competition-detail_list-item">
        {!!fixtureList.length && (
          <div className="match_change_buttons">
            <button type="button" className="btn-link-item previous round" onClick={previousMatch}>
              <span className="previous_element">‹</span>
            </button>
            <button type="button" className="btn-link-item next round" onClick={nextMatch}>
              <span className="next_element">›</span>
            </button>
          </div>
        )}
        <div className="competition-detail_match">
          <div className="competition-detail_match_hometeam">
            <span className="competition-detail_match_teamname">{homeTeamName.current}</span>
            <div className="competition-detail_logo">
              <div className="image image-loading">
                <img className="image_wrapper team-logo team-logo-small"
                     height={20}
                     width={20}
                     src={homeTeamLogo.current} alt=""/>
              </div>
            </div>
          </div>
          {match?.status?.started && (
            <div className="competition-detail_match_status">{match?.status?.scoreStr}</div>
          )}
          {!match?.status?.started && (
            <div className="competition-detail_match_status"> V </div>
          )}
          <div className="competition-detail_match_awayteam">
            <div className="competition-detail_logo">
              <div className="image image-loading">
                <img className="image_wrapper team-logo team-logo-small"
                     height={20}
                     width={20}
                     src={awayTeamLogo.current}
                     alt="" />
              </div>
            </div>
            <span className="competition-detail_match_teamname">{awayTeamName.current}</span>
          </div>
        </div>
      </div>
      <div className="competition-detail_match-date">{match?.status?.startDateStr}</div>
      <div className="competition-detail_double-points ng-star-inserted">
        {competition?.owner && token?.user_name && match?.id && (
          <DoublePoints competitionId={competitionId.current}
                        matchId={match.id}
                        owner={competition.owner}
                        user={token.user_name}/>
        )}
        <LineupOnlyToggle toggleFunction={toggleLineupOnly} currentState={onlyLineup}/>
      </div>
      <div className="competition-detail_player-group">Attackers</div>
      {selections && token?.user_name && match?.id && players?.attacker.map(player => (
        <Player key={player.id} toggleSelection={toggleSelection} rules={rules} player={player}
                selections={selections} matchId={match?.id} user={token?.user_name}
                isOldMatch={activeMatchIndex.current > matchIndex.current}
                isFutureMatch={activeMatchIndex.current < matchIndex.current}
                showOnlyLineup={onlyLineup}/>
      ))}
      <div className="competition-detail_player-group">Midfielders</div>
      {selections && token?.user_name && match?.id && players?.midfielder.map(player => (
        <Player key={player.id} toggleSelection={toggleSelection} rules={rules} player={player}
                selections={selections} matchId={match?.id} user={token?.user_name}
                isOldMatch={activeMatchIndex.current > matchIndex.current}
                isFutureMatch={activeMatchIndex.current < matchIndex.current}
                showOnlyLineup={onlyLineup}/>
      ))}
      <div className="competition-detail_player-group">Defenders</div>
      {selections && token?.user_name && match?.id && players?.defender.map(player => (
        <Player key={player.id} toggleSelection={toggleSelection} rules={rules} player={player}
                selections={selections} matchId={match?.id} user={token?.user_name}
                isOldMatch={activeMatchIndex.current > matchIndex.current}
                isFutureMatch={activeMatchIndex.current < matchIndex.current}
                showOnlyLineup={onlyLineup}/>
      ))}
      <div className="competition-detail_player-group">Goalkeepers</div>
      {selections && token?.user_name && match?.id && players?.keeper.map(player => (
        <Player key={player.id} toggleSelection={toggleSelection} rules={rules} player={player}
                selections={selections} matchId={match?.id} user={token?.user_name}
                isOldMatch={activeMatchIndex.current > matchIndex.current}
                isFutureMatch={activeMatchIndex.current < matchIndex.current}
                showOnlyLineup={onlyLineup}/>
      ))}
    </>
  )
}

export default PredictorDetailsSelection;
