import "./CompetitionCreate.scss";

import {useEffect, useState} from 'react';
import PredictorRules from "../PredictorRules/PredictorRules";
import {FixtureService, PredictorService} from "../../service";

const predictorTypes = ['goal', 'assist', 'yellow_card', 'red_card'];
const predictorSelections = ['first', 'per'];
const scorePredictorTypes = ['result', 'team_score', 'goal_diff'];
const scorePredictorSelections = ['score'];
const Types = {
    predictor: 'Predictor',
    lms: 'Last Man Standing',
    scorePredictor: 'Score Predictor'
};

const CompetitionCreate = () => {
    const [name, setName] = useState();
    const [type, setType] = useState('predictor');
    const [ccode, setCcode] = useState("ENG");
    const [leagues, setLeagues] = useState();
    const [lives, setLives] = useState(3);
    const [leagueId, setLeagueId] = useState();
    const [teamId, setTeamId] = useState();
    const [rules, setRules] = useState([]);
    const [scoreRules, setScoreRules] = useState([]);
    const [teams, setTeams] = useState();

    function createRule() {
        return {
            selection: 'first',
            type: 'goal',
            points: 5,
            multiple: false,
            splitPoints: false,
        }
    }

    function createScoreRule() {
        return {
            selection: 'score',
            type: 'result',
            points: 3,
            multiple: false,
            splitPoints: false,
        }
    }

    useEffect(() => {
        const baseRule = createRule()
        const baseScoreRule = createScoreRule()
        setRules([baseRule])
        setScoreRules([baseScoreRule])
    }, [])

    useEffect(() => {
        async function setLeagueTeams() {
            const teams = await FixtureService.getLeagueTeams(ccode, leagueId);
            setTeams(teams);
        }

        async function setCountryLeagues() {
            const leagues = await FixtureService.getLeagues(ccode);
            setLeagues(leagues);
            setLeagueId(leagues[0].id);
            setLeagueTeams();
        }
        setCountryLeagues();
    }, [ccode, leagueId]);

    useEffect(() => {
        if (teams) {
            setTeamId(teams[0].id);
        }
    }, [teams]);

    const addRule = () => {
        const updatedRules = rules.slice(0);
        updatedRules.push(createRule());
        setRules(updatedRules);
    }

    const updateRule = (index, rule) => {
        const updatedRules = rules.slice(0);
        updatedRules[index] = rule;
        setRules(updatedRules);
    }

    const removeRule = (index) => {
        const updatedRules = [];
        rules.forEach((rule, rulesIndex) => {
            if (rulesIndex !== index) {
                updatedRules.push(rule);
            }
        })
        setRules(updatedRules);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        alert(`Submitting Name ${name} ${type}`);
        const comp = {
            name: name,
            type: type,
            leagueId: leagueId,
            teamId: teamId,
            selectedTeamOnly: true,
            open: false,
            ruleList: type === 'predictor' ? rules : scoreRules,
            lives: lives,
        }
        PredictorService.createComp(comp)
            .then(() => console.log("Success"))
            .catch(() => console.error("Failed to create"));
    }

    return (
        <div className="content_main">
            <form className="form-competition-create" onSubmit={handleSubmit}>
                <div className="form-competition-create-content">
                    <span className="form-competition-create-heading">Create your own competition</span>
                    <fieldset className="form-competition-content-border">
                        <legend>Details</legend>
                        <div>
                            <label htmlFor="inputName" className="form-label">Name</label>
                            <input type="text" id="inputName" name="name" className="form-control"
                                   onChange={evt => setName(evt.target.value)} required autoFocus />
                        </div>
                        <div>
                            <label htmlFor="inputType" className="form-label">
                                Type
                            </label>
                            <select className="form-control" name="type" onChange={evt => setType(evt.target.value)}>
                                {Object.entries(Types).map(([name, value]) => (
                                    <option value={name}>{value}</option>
                                ))}
                            </select>
                        </div>
                        {type && type === 'lms' && (
                            <div>
                                <label htmlFor="inputLives" className="form-label">Lives</label>
                                <input type="number" id="inputLives" name="lives" className="form-control lives-input"
                                       step="1" onChange={event => setLives(event.target.value)} required autoFocus />
                            </div>
                        )}
                        {type && type === 'predictor' && (
                            <div>
                                <label htmlFor="inputCountry" className="form-label">Country</label>
                                <select className="form-control" name="ccode"
                                        onChange={event => setCcode(event.target.value)}>
                                    <option value="ENG">England</option>
                                </select>
                            </div>
                        )}
                        {leagues && type === 'predictor' && (
                            <div>
                                <label htmlFor="inputLeague" className="form-label">League</label>
                                <select className="form-control" name="leagueId"
                                        onChange={evt => setLeagueId(evt.target.value)} >
                                    {leagues.map(league => (
                                        <option key={league.id} value={league.id}>
                                            {league.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {teams && type === 'predictor' && (
                            <div>
                                <label htmlFor="inputTeam" className="form-label">Team</label>
                                <select className="form-control" name="teamId"
                                        onChange={event => setTeamId(event.target.value)}>
                                    {teams.map(team =>
                                        <option key={team.id} value={team.id}>{team.name}</option>
                                    )}
                                </select>
                            </div>
                        )}
                    </fieldset>
                    {!!rules && type === 'predictor' && rules.map((rule, index) =>
                        <PredictorRules key={index}
                                        isNew={true}
                                        rules={rules}
                                        ruleCount={rules.length}
                                        removeRule={removeRule}
                                        selections={predictorSelections}
                                        types={predictorTypes}
                                        updateRule={updateRule}/>
                    )}
                    {!!rules && type === 'scorePredictor' && rules.map((rule, index) =>
                      <PredictorRules key={index}
                                      isNew={true}
                                      rules={scoreRules}
                                      ruleCount={scoreRules.length}
                                      removeRule={removeRule}
                                      selections={scorePredictorSelections}
                                      types={scorePredictorTypes}
                                      updateRule={updateRule}/>
                    )}
                    <button className="btn btn-lg btn-primary btn-block form-button" type="submit">Create</button>
                    {!!rules && rules.length < 3 &&
                        <button className="rule-add-button" type="button" onClick={addRule}>+</button>
                    }
                </div>
            </form>
        </div>
    )
}

export default CompetitionCreate;
