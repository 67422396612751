import "./PredictorDetails.scss";
import {useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import {TabContent, TabLink, Tabs} from "react-tabs-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import PredictorDetailsSelection from "../PredictorDetailsSelection/PredictorDetailsSelection";
import PredictorDetailsResults from "../PredictorDetailsResults/PredictorDetailsResults";
import PredictorRules from "../PredictorRules/PredictorRules";
import {AuthService, FixtureService, PredictorService} from "../../service";

const types = ['goal', 'assist', 'yellow_card', 'red_card'];
const selections = ['first', 'per'];
const ruleMap = {
    'goal': {display: 'Goal', tooltip: 'Correct Goal Scorer'},
    'assist': {display: 'Assist', tooltip: 'Correct Assist'},
}

const PredictorDetails = () => {
    const [token, setToken] = useState();
    const [competition, setCompetition] = useState();
    const [competitionCode, setCompetitionCode] = useState();
    const [rules, setRules] = useState();
    const [teamInfo, setTeamInfo] = useState();
    const location = useLocation();

    let competitionId = useRef(0);

    useEffect(() => {
        async function setAuthToken() {
            const auth = await AuthService.getAuth();
            setToken(auth);
        }
        setAuthToken();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        competitionId.current = searchParams.get('id');
        async function fetchData() {
            const competition = await PredictorService.getCompetitionDetails(competitionId.current);
            setCompetition(competition);
            setCompetitionCode(competition?.code);
            setRules(competition.ruleList);
        }
        fetchData();
    }, [location]);

    useEffect(() => {
        async function update() {
            const teamInfo = await FixtureService.getTeamInfo(competition?.teamId);
            setTeamInfo(teamInfo);
        }
        update();
    }, [competition]);

    const updateRule = (index, rule) => {
        rules[index] = rule;
        PredictorService.updateRules(competitionId.current, rules)
            .catch(() => console.error('Failed to update rules'))
    }

    async function regenerateCode() {
        const newCode = await PredictorService.regenerateCode(competition.competitionId);
        setCompetitionCode(newCode);
    }

    return (
        <div className="content_main">
            <fieldset className="form-competition-content-border">
                <legend>{competition?.name}</legend>
                <section className="competition_group">
                    {token?.user_name === competition?.owner && (
                        <h4 className="competition_group_title">
                            <div className="image competition_group_flag image-loading">
                                <div className="competition_match_teamname">
                                    (Code: {competitionCode})
                                    <FontAwesomeIcon className="redo-icon" icon={faRedo} onClick={regenerateCode} />
                                </div>
                            </div>
                        </h4>
                    )}
                    <h4 className="competition_group_title">
                        <div className="image competition_group_flag image-loading">
                            <span className="competition_match_teamname">{teamInfo?.details?.name}</span>
                        </div>
                    </h4>
                    <Tabs className="competition-tabset">
                        <ul className="nav nav-tabs justified-content-start">
                            <li className="nav-item">
                                <TabLink to="selection">Selection</TabLink>
                            </li>
                            <li className="nav-item">
                                <TabLink to="table">Table</TabLink>
                            </li>
                            {token?.user_name === competition?.owner && (
                                <li className="nav-item">
                                    <TabLink to="rules">Rules</TabLink>
                                </li>
                            )}
                        </ul>

                        <TabContent for="selection">
                            {teamInfo &&
                              <PredictorDetailsSelection competition={competition} teamInfo={teamInfo} rules={rules}/>
                            }
                        </TabContent>
                        <TabContent for="table">
                            <PredictorDetailsResults competition={competition} ruleMap={ruleMap}/>
                        </TabContent>
                        <TabContent for="rules">
                            {rules &&
                                <PredictorRules
                                  types={types}
                                  selections={selections}
                                  isNew={false}
                                  rules={rules}
                                  updateRule={updateRule}
                                />
                            }
                        </TabContent>
                    </Tabs>
                </section>
            </fieldset>
        </div>
    );
}

export default PredictorDetails;
