import './Header.scss';
import Menu from "../Menu/Menu";

const HeaderMenu = () => {
    return (
        <div className="header_menu">
            <Menu pageWrapId={"page-wrap"} outerContainerId={"App"} />
        </div>
    )
}

export default HeaderMenu;
