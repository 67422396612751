const ErrorMessage = ({message}) => {
    return (
        <div className="error-message">
            <div className="error-text">
                {message}
            </div>
        </div>
    )
}

export default ErrorMessage;
