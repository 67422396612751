import * as React from "react";
import {Route, Switch} from "react-router-dom";

import {PrivateRoute} from "./PrivateRoute";
import Home from "../components/Home";
import News from "../components/News/News";
import CompetitionList from "../components/Competition/CompetitionList";
import PredictorDetails from "../components/PredictorDetails/PredictorDetails";
import LmsDetails from "../components/LmsDetails/LmsDetails";
import ScorePredictorDetails from "../components/ScorePredictorDetails/ScorePredictorDetails";
import CompetitionCreate from "../components/CompetitionCreate/CompetitionCreate";
import {Profile} from "../components/Profile";
import Login from "../components/Login/Login";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import Admin from "../components/Admin/Admin";
import Clear from "../components/Clear/Clear";


export const Routes = (
  <Switch>
    <Route exact={true} path="/" component={Home}/>
    <Route exact={true} path="/news" component={News}/>
    <Route exact={true} path="/login" component={Login}/>
    <Route exact={true} path="/reset" component={ResetPassword}/>
    <Route exact={true} path="/clear" component={Clear}/>
    <PrivateRoute exact={true} path="/admin" component={Admin}/>
    <PrivateRoute exact={true} path="/competition" component={CompetitionList}/>
    <PrivateRoute exact={true} path="/competition/predictor" component={PredictorDetails}/>
    <PrivateRoute exact={true} path="/competition/lms" component={LmsDetails}/>
    <PrivateRoute exact={true} path="/competition/score" component={ScorePredictorDetails}/>
    <PrivateRoute exact={true} path="/create" component={CompetitionCreate}/>
    <PrivateRoute exact={true} path="/profile" component={Profile}/>
  </Switch>
)
