import "./Admin.scss";
import * as React from "react";
import {useEffect, useState} from "react";
import {AuthService, PredictorService} from "../../service";
import {Button} from "react-bootstrap";

const Admin = () => {

  const [accounts, setAccounts] = useState([]);
  const [impersonateId, setImpersonateId] = useState();

  useEffect(() => {
    AuthService.getAccount().then(setAccounts)
    setImpersonateId(localStorage.getItem('impersonating'))
  }, [impersonateId]);

  function impersonateUser(id) {
    AuthService.impersonateUser(id).then(setImpersonateId)
  }

  function stopImpersonating() {
    AuthService.stopImpersonating()
    setImpersonateId(null)
  }

  function resetCompetitions() {
    PredictorService.resetCompetitions().then()
  }

  return (
    <div className="content_main">
      <table className="competition-table">
        <thead className="competition-table-head">
        <tr>
          <td>Name</td>
          <td/>
        </tr>
        </thead>
        <tbody className="competition-table-body">
        {accounts?.map(account =>
          <tr key={account.username}>
            <td>{account.firstName} {account?.lastName}</td>
            {!!impersonateId && impersonateId === account.id && (
              <td><Button onClick={stopImpersonating}>Stop</Button></td>
            )}
            {!!impersonateId && impersonateId !== account.id && (
              <td/>
            )}
            {!impersonateId && (
              <td><Button onClick={() => impersonateUser(account.id)}>Impersonate</Button></td>
            )}
          </tr>
        )}
        </tbody>
      </table>
      <div className={"reset-competitions"}>
        <Button onClick={() => resetCompetitions()}>Reset Competitions</Button>
      </div>
    </div>
  )
}

export default Admin;
