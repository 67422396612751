import "./News.scss";

import {useEffect, useState} from "react";
import {RssFeed} from './rss-feed';
import {NewsService} from "../../service";

const BBC_RSS = 'http://feeds.bbci.co.uk/sport/football/rss.xml?uk';
const SKY_SPORTS_RSS = 'https://www.skysports.com/rss/12040';
const MIRROR_RSS = 'https://www.mirror.co.uk/sport/?service=rss';

const News = () => {

  const [feeds: RssFeed[], addFeeds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const bbcRss = await NewsService.getRssFeed(BBC_RSS);
      const skySportsRss = await NewsService.getRssFeed(SKY_SPORTS_RSS);
      const mirrorRss = await NewsService.getRssFeed(MIRROR_RSS);
      delete bbcRss.feed.image;
      skySportsRss.items = skySportsRss.items.filter(item => item.link.includes('/football/'));
      mirrorRss.items = mirrorRss.items.filter(item => item.link.includes('/football/'));
      const rssFeeds = [bbcRss, skySportsRss, mirrorRss];
      addFeeds(rssFeeds)
    }
    fetchData();
  }, []);

  return (
    <div className="content_main">
      {feeds.map((feed, index) => (
        <div key={index} className="news-feed">
          <h2>
            {feed?.feed.image && (
              <img src={feed?.feed.image} alt={feed.feed.title}/>
            )}
            {!feed?.feed.image &&
            <>{feed?.feed.title}</>
            }
          </h2>
          <ul className="news-feed-item">
            {feed?.items.map(item => (
              <li key={item.guid}>
                <a className="feed-link" href={item.link}>{item.title}</a>
                <div className="feed-date">{item.pubDate}</div>
                <div className="feed-description">{item.description}</div>
              </li>
            ))}
          </ul>
        </div>
      ))}

    </div>
  )
}

export default News;
